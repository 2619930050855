//apiResponseProcessor.js

export class ApiError extends Error {
    constructor(type, errors) {
        super();
        this.name = 'ApiError';
        this.type = type;
        this.errors = errors;
    }
}

export const handleResponse = async (response) => {
    const data = await response.json();
    
    if (!response.ok) {
        if (data.type && data.errors) {
            throw new ApiError(data.type, data.errors);
        }
        throw new Error('API request failed');
    }
    
    return data;
};