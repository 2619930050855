import React from 'react';
import { motion } from 'framer-motion';
import { Trash2, ChevronDown, AlertCircle } from 'lucide-react';
import CustomDropdown from '../../../../../../../components/reusableComponents/CustomDropdown/CustomDropdown';
import ValidationParam from './ValidationParam';
import styles from './ValidationRow.module.css';

const ValidationRow = ({ 
  validation,
  validationTypeOptions,
  validationTypes = {},
  onChange,
  onDelete,
  errors = {},
  disabled = false,
  index = 0,
  disabledItems = []
}) => {
  // Add a guard clause for when validationType is undefined
  const validationType = validationTypes[validation.type] || {};
  const params = validationType?.params?.sort((a, b) => a.order - b.order) || [];
  const hasTypeError = Boolean(errors.type);

  const handleTypeChange = (newType) => {
    onChange(validation.id, 'type', newType);
  };

  const handleParamChange = (paramName, value) => {
    const updatedParams = {
      ...validation.params,
      [paramName]: value
    };
    onChange(validation.id, 'params', updatedParams);
  };

  // Only render the full component if we have validation types
  return (
    <motion.div
      className={styles.wrapper}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={styles.row}>
        <div className={styles.leftContent}>
          <div className={styles.indexCell}>{index + 1}</div>
          <div className={styles.functionCell}>
            <label className={styles.label}>
              Function
              <span className={styles.required}>*</span>
            </label>
            <div className={styles.dropdownWrapper}>
              <CustomDropdown
                name={`${validation.id}-type`}
                items={validationTypeOptions}
                value={validation.type}
                onChange={handleTypeChange}
                placeholder="Select function"
                disabled={disabled}
                icon={<ChevronDown size={16} className={styles.chevron} />}
                style={{ 
                  height: "2.375rem",
                  borderColor: hasTypeError ? '#EF4444' : undefined 
                }}
                className={hasTypeError ? styles.errorField : ''}
                disabledItems={disabledItems}
                required
                aria-invalid={hasTypeError}
              />
              {hasTypeError && (
                <div className={styles.errorMessage}>
                  <AlertCircle size={12} className={styles.errorIcon} />
                  <span>{errors.type}</span>
                </div>
              )}
            </div>
          </div>
          {validationType && (
            <div className={styles.parameterCells}>
              {params.map(param => (
                <ValidationParam
                  key={param.name}
                  param={param}
                  value={validation.params[param.name] || validation.params?.value}
                  onChange={handleParamChange}
                  error={errors[param.name]}
                  validation={validation}
                />
              ))}
            </div>
          )}
        </div>
        <div className={styles.deleteCell}>
          <button
            onClick={() => onDelete(validation.id)}
            className={styles.deleteButton}
            disabled={disabled}
            type="button"
            aria-label="Delete validation"
          >
            <Trash2 size={16} />
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default React.memo(ValidationRow);