// Existing action types
export const SELECT_TILE = 'SELECT_TILE';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_BASEURL = 'SET_BASEURL';
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS';
export const RESET_HISTORY = 'RESET_HISTORY';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const SETNEW_PASSWORD_REQUEST = 'SETNEW_PASSWORD_REQUEST';
export const SETNEW_PASSWORD_SUCCESS = 'SETNEW_PASSWORD_SUCCESS';
export const SETNEW_PASSWORD_FAILURE = 'SETNEW_PASSWORD_FAILURE';

// workflow
export const SET_MODE = 'SET_MODE';
export const SET_SELECTED_NODE = 'SET_SELECTED_NODE';
export const SET_NODES = 'SET_NODES';

// New notification action types
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

// Existing action creators
export const selectTile = (tileId) => ({
    type: SELECT_TILE,
    payload: tileId,
});



export const showSidebar = () => ({
    type: SHOW_SIDEBAR,
});

export const hideSidebar = () => ({
    type: HIDE_SIDEBAR,
});

export const setBaseURL = (baseURL) => ({
    type: SET_BASEURL,
    payload: baseURL,
});

export const updateBreadcrumbs = breadcrumbs => ({
    type: UPDATE_BREADCRUMBS,
    payload: breadcrumbs
});

export const resetHistory = () => ({
    type: RESET_HISTORY
});

// New notification action creator
export const updateNotifications = (notifications) => ({
    type: UPDATE_NOTIFICATIONS,
    payload: notifications
});

// workflow
export const setMode = (mode) => ({
    type: SET_MODE,
    payload: mode,
});

export const setSelectedNode = (nodeId) => ({
    type: SET_SELECTED_NODE,
    payload: nodeId,
});

export const setNodeCollection = (nodes) => ({
    type: SET_NODES,
    payload: nodes,
});
  