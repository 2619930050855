// MappingFieldRowHelpers.js

import TransformationContent from "./TransformationContent/TransformationContent";
import LookupContent from "./LookupContent/LookupContent";
import ExpressionContent from "./ExpressionContent/ExpressionContent";
import ValidationConfig from "./ValidationConfig/ValidationConfig";
import StaticValueContent from "./StaticValueContent/StaticValueContent";

export const MAPPING_TYPES = {
  STANDARD: 'standard',
  STATIC: 'static',
  TRANSFORM: 'transform',
  LOOKUP: 'lookup',
  EXPRESSION: 'expression'
};

export const transformSchemaToDropdownFormat = (schemaObj, parentKey = '') => {
  if (!schemaObj || typeof schemaObj !== 'object') {
    return [];
  }

  const schema = schemaObj.schema || schemaObj;
  
  return Object.entries(schema).map(([key, value]) => {
    // Remove any [*] from the parent key if it exists
    const cleanParentKey = parentKey.replace(/\[\*\]/g, '');
    const currentKey = cleanParentKey ? `${cleanParentKey}.${key}` : key;
    
    if (value.type) {
      const result = {
        value: currentKey,
        label: key,
        type: value.type,
        ...(value.required && { required: value.required }),
        ...(value.regex && { regex: value.regex }),
        ...(value.allowed && { allowed: value.allowed }),
        ...(value.min !== undefined && { min: value.min }),
        ...(value.max !== undefined && { max: value.max })
      };

      if (value.type === 'list' && value.schema) {
        result.isArray = true;
        // For array children, we pass the current key without [*]
        const children = transformSchemaToDropdownFormat(value.schema, currentKey);
        if (children.length) result.children = children;
      } else if (value.type === 'dict' && value.schema) {
        const children = transformSchemaToDropdownFormat(value.schema, currentKey);
        if (children.length) result.children = children;
      }

      return result;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      const children = transformSchemaToDropdownFormat(value, currentKey);
      return {
        value: currentKey,
        label: key,
        ...(children.length && { children })
      };
    }

    return { value: currentKey, label: key };
  });
};

export const findDataTypeInSchema = (schema, path) => {
  if (!schema || !path) return null;
  
  let current = schema.type === 'dict' && schema.schema ? schema.schema : schema;
  
  try {
    for (const part of path.split('.')) {
      if (!current[part]) return null;
      current = current[part];
      current = current.type === 'dict' && current.schema ? current.schema : current;
    }
    return current?.type || null;
  } catch {
    return null;
  }
};

export const createPopupContent = (mappingField) => {
  console.log('Creating popup content for mapping field:', mappingField);
  
  return {
    [MAPPING_TYPES.TRANSFORM]: {
      title: "Define Transformation",
      Component: TransformationContent,
      props: {
        initialSteps: mappingField.transformation?.steps || []
      }
    },
    [MAPPING_TYPES.LOOKUP]: {
      title: "Define Lookup",
      Component: LookupContent,
      props: {
        initialConfig: mappingField.lookup || null
      }
    },
    [MAPPING_TYPES.EXPRESSION]: {
      title: "Define Expression",
      Component: ExpressionContent,
      props: {
        initialConfig: mappingField.expression || null
      }
    },
    [MAPPING_TYPES.STATIC]: {
      title: "Define Static Value",
      Component: StaticValueContent,
      props: {
        initialValue: mappingField.staticValue || ''
      }
    },
    validation: {
      title: "Configure Field Validation",
      Component: ValidationConfig,
      props: {
        initialConfig: mappingField.validations || null,
        fieldInfo: {
          sourceField: mappingField.sourceField,
          sourceType: mappingField.sourceType,
          targetField: mappingField.targetField,
          targetType: mappingField.targetType
        }
      }
    }
  };
};

export const getActionButtonStates = (mappingField) => ({
  showTransform: mappingField.mappingType === MAPPING_TYPES.TRANSFORM,
  showLookup: mappingField.mappingType === MAPPING_TYPES.LOOKUP,
  showExpression: mappingField.mappingType === MAPPING_TYPES.EXPRESSION,
  showStatic: mappingField.mappingType === MAPPING_TYPES.STATIC,
  hasValidations: !!mappingField.validations?.length
});