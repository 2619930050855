// ConfigurationLayout.jsx
import React, { useRef } from 'react';
import { Plus } from 'lucide-react';
import ControlsSection from '../sharedHelpers/ControlsSection';
import EmptyState from '../../../../../../../components/reusableComponents/EmptyState/EmptyState';
import styles from './ConfigurationLayout.module.css';

const ConfigurationLayout = ({
  items,
  onAdd,
  onSave,
  onClose,
  onUndo,
  onRedo,
  canUndo,
  canRedo,
  emptyStateIcon,
  emptyStateTitle,
  emptyStateMessage,
  headerTitle,
  headerSubtitle,
  renderItem,
}) => {
  const formRef = useRef(null);

  return (
    <div className={styles.container}>
      <ControlsSection
        onAdd={onAdd}
        onUndo={onUndo}
        onRedo={onRedo}
        onSave={onSave}
        onClose={onClose}
        canUndo={canUndo}
        canRedo={canRedo}
        showAddButton={!items.length}
      />

      <form ref={formRef} onSubmit={onSave} noValidate className={styles.content}>
        {!items.length ? (
          <EmptyState
            icon={emptyStateIcon}
            title={emptyStateTitle}
            message={emptyStateMessage}
          />
        ) : (
          <>
            <div className={styles.header}>
              <h3 className={styles.headerTitle}>
                {headerTitle} <span className={styles.required}>*</span>
              </h3>
              <p className={styles.headerSubtitle}>{headerSubtitle}</p>
            </div>

            <div className={styles.itemsList}>
              {items.map((item, index) => renderItem(item, index))}
            </div>

            <button
              onClick={onAdd}
              className={`${styles.addButton} ${!items.length ? styles.disabled : ''}`}
              type="button"
              aria-label="Add item"
            >
              <Plus size={16} />
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default ConfigurationLayout;