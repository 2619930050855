export const dataTypeOptions = [
  { value: 'string', label: 'String' },
  { value: 'number', label: 'Number' },
  { value: 'float', label: 'Float' },
  { value: 'boolean', label: 'Boolean' },
  { value: 'date', label: 'Date' },
  { value: 'datetime', label: 'DateTime' },
  { value: 'object', label: 'Object' },
  { value: 'array', label: 'Array' },
  { value: 'integer', label: 'Integer' },
  { value: 'dict', label: 'Dictionary' },
  { value: 'list', label: 'List' },
  { value: 'regex', label: 'Regular Expression' }
];


export const mappingTypeOptions = [
  { value: 'standard', label: 'Standard' },
  { value: 'static', label: 'Static' },
  { value: 'transform', label: 'Transform' },
  { value: 'lookup', label: 'Lookup' },
  { value: 'expression', label: 'Expression' }
];

export const dropdownStyle = {
  height: '34px',
  boxSizing: 'border-box',
  borderRadius: '8px'
};