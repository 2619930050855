// fieldValidationUtils.js

const SCHEMA_STRUCTURE_PROPERTIES = new Set([
  'type',
  'schema',
  'dynamic',
  'properties'
]);

const createValidationObject = (type, value) => ({
  type,
  params: { value }
});

// Helper function to normalize paths
const normalizePath = (path) => {
  return path.toLowerCase().replace(/\s/g, '');
};

const extractValidations = (fieldSchema) => {
  console.log('Extracting validations from schema:', fieldSchema);
  
  if (!fieldSchema || typeof fieldSchema !== 'object') {
    return [];
  }

  const validations = [];

  // If we have a schema property, check if it has nested validations
  if (fieldSchema.schema) {
    return extractValidations(fieldSchema.schema);
  }

  // Extract direct validation properties
  Object.entries(fieldSchema).forEach(([key, value]) => {
    if (SCHEMA_STRUCTURE_PROPERTIES.has(key)) return;

    if ((key === 'required' || key === 'empty' )&& value === true) {
      validations.push(createValidationObject(key, true));
      return;
    }

    if (key === 'allowed' && Array.isArray(value)) {
      validations.push(createValidationObject('allowed', value));
      return;
    }

    if (key === 'regex' && value) {
      validations.push(createValidationObject('regex', value));
      return;
    }

    if (key === 'min' && value !== undefined) {
      validations.push(createValidationObject('min', value));
      return;
    }

    if (key === 'max' && value !== undefined) {
      validations.push(createValidationObject('max', value));
      return;
    }

    if (value !== undefined && key !== 'required' && typeof value !== 'object') {
      validations.push(createValidationObject(key, value));
    }
  });

  return validations;
};

const findFieldSchemaByPath = (schema, fieldPath) => {
  console.log('Finding schema for path:', fieldPath);
  
  if (!schema || !fieldPath) return null;

  // Normalize the input path
  const normalizedPath = normalizePath(fieldPath);
  const parts = normalizedPath.split('.');
  let currentSchema = schema;

  for (const part of parts) {
    if (!currentSchema) return null;

    if (currentSchema.type === 'dict' && currentSchema.schema) {
      // Find the matching key case-insensitively
      const matchingKey = Object.keys(currentSchema.schema)
        .find(key => normalizePath(key) === part);
      
      if (matchingKey) {
        currentSchema = currentSchema.schema[matchingKey];
      } else {
        return null;
      }
    } 
    else if (currentSchema.type === 'list' && currentSchema.schema) {
      if (currentSchema.schema.schema) {
        // Find the matching key case-insensitively
        const matchingKey = Object.keys(currentSchema.schema.schema)
          .find(key => normalizePath(key) === part);
        
        if (matchingKey) {
          currentSchema = currentSchema.schema.schema[matchingKey];
        } else {
          currentSchema = currentSchema.schema;
        }
      } else {
        currentSchema = currentSchema.schema;
      }
    }
    else {
      // Find the matching key case-insensitively
      const matchingKey = Object.keys(currentSchema)
        .find(key => normalizePath(key) === part);
      
      if (matchingKey) {
        currentSchema = currentSchema[matchingKey];
      } else {
        return null;
      }
    }

    // If we found a validation object directly, return it
    if (currentSchema && !currentSchema.schema && 
       (currentSchema.type || currentSchema.allowed || currentSchema.regex)) {
      return currentSchema;
    }
  }

  return currentSchema;
};

const updateMappingFieldValidations = (mappingField, schema, targetPath) => {
  console.log('=== Starting updateMappingFieldValidations ===');
  console.log('Input mappingField:', mappingField);
  console.log('Input schema:', schema);
  console.log('Input targetPath:', targetPath);

  // Always use mappingField.targetField if it exists
  if (!targetPath) {
    console.warn('No targetField in mappingField');
    return mappingField;
  }

  // Clear existing validations before adding new ones
  const cleanedMappingField = {
    ...mappingField,
    validations: []
  };

  const fieldSchema = findFieldSchemaByPath(schema, targetPath);
  console.log('Found field schema for path:', targetPath, fieldSchema);

  if (!fieldSchema) {
    console.warn(`No schema found for path: ${targetPath}`);
    return cleanedMappingField;
  }

  const validations = extractValidations(fieldSchema);
  console.log('Extracted validations:', validations);

  return {
    ...cleanedMappingField,
    validations
  };
};

export default updateMappingFieldValidations;