import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { fetchWorkflowNodesApi } from "../../../api/workflowApi";
import { useDnD } from "../DnDProvider/DnDProvider";
import { transformNodeData } from "../helpers/transformNodeData";
import { setNodeCollection } from "../../../redux/actions/actions";
import styles from "./Sidebar.module.css";
import { ReactComponent as DropdownIconClosed } from "../../../assetts/images/dropdownIconClosed.svg";
import { ReactComponent as SearchIcon } from "../../../assetts/images/searchIcon.svg";
import Tooltip from "@mui/material/Tooltip";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { setDraggedNodeType } = useDnD();
  const [expandedCategories, setExpandedCategories] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [nodes, setNodes] = useState({});
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [isDragging, setIsDragging] = useState(false);

  const toggleExpand = (categoryKey) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryKey]: !prev[categoryKey],
    }));
  };

  const collectAllNodes = (nodesData) => {
    const allNodes = [];
    Object.values(nodesData).forEach(category => {
      if (category.nodes && Array.isArray(category.nodes)) {
        allNodes.push(...category.nodes);
      }
    });
    return allNodes;
  };

  const onDragStart = (event, node) => {
    event.dataTransfer.setData("application/reactflow", JSON.stringify(node));
    event.dataTransfer.effectAllowed = "move";
    setDraggedNodeType(node);
    setIsDragging(true);
  };

  const onDragEnd = () => {
    setIsDragging(false);
  };

  const filterNodes = (categoryNodes) => {
    return categoryNodes.nodes.filter((node) =>
      node.data?.dynamic?.properties.label.toLowerCase().includes(debouncedSearchTerm)
    );
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const performSearch = async () => {
      try {
        const params = debouncedSearchTerm ? { name: debouncedSearchTerm } : {};
        const incomingData = await fetchWorkflowNodesApi(accessToken, params);
        const transformedData = transformNodeData(incomingData);
        setNodes(transformedData);

        // Collect and dispatch all nodes to Redux
        const allNodes = collectAllNodes(transformedData);
        dispatch(setNodeCollection(allNodes));
      } catch (error) {
        console.error("Error fetching nodes:", error);
      }
    };

    performSearch();
  }, [debouncedSearchTerm, accessToken, dispatch]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  return (
    <div className={styles.sidebarContainer}>
      <motion.div 
        className={styles.searchContainer}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <SearchIcon className={styles.searchIcon} />
        <input
          type="text"
          placeholder="Search Integrations..."
          className={styles.searchInput}
          value={searchTerm}
          onChange={handleSearch}
        />
      </motion.div>

      <motion.aside 
        className={styles.sidebar}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        {Object.entries(nodes).map(([categoryKey, categoryData]) => (
          <motion.div 
            className={styles.categorySection} 
            key={categoryKey}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className={styles.categoryHeader}
              onClick={() => toggleExpand(categoryKey)}
              whileHover={{ backgroundColor: "#f9f9f9" }}
            >
              <img
                src={categoryData.groupIcon}
                alt={`${categoryData.groupLabel} icon`}
                className={styles.categoryIcon}
              />
              <Tooltip title={categoryData.groupLabel} arrow>
                <span className={styles.categoryTitle}>
                  {categoryData.groupLabel}
                </span>
              </Tooltip>
              <motion.div
                animate={{ rotate: expandedCategories[categoryKey] ? 90 : 0 }}
                transition={{ duration: 0.2 }}
              >
                <DropdownIconClosed className={styles.dropdownIcon} />
              </motion.div>
            </motion.div>

            <AnimatePresence>
              {expandedCategories[categoryKey] && (
                <motion.div
                  className={styles.nodeList}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  {filterNodes(categoryData).map((node, index) => (
                    <motion.div
                      key={node.id}
                      className={styles.nodeItem}
                      draggable
                      onDragStart={(event) => onDragStart(event, node)}
                      onDragEnd={onDragEnd}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ 
                        duration: 0.2,
                        delay: index * 0.05
                      }}
                      whileHover={{ 
                        scale: 1.02,
                        transition: { duration: 0.2 }
                      }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <span className={styles.nodeLabel}>
                        {node.data?.dynamic?.properties.label}
                      </span>
                      <motion.div 
                        className={styles.nodeIcon}
                        whileHover={{ 
                          scale: 1.1,
                          transition: { duration: 0.2 }
                        }}
                      >
                        <img
                          src={node.data?.dynamic?.properties.icon}
                          alt={node.data?.dynamic?.properties.label}
                          className={styles.nodeIconImage}
                        />
                      </motion.div>
                    </motion.div>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </motion.aside>
    </div>
  );
};

export default Sidebar;