import React from 'react';
import { AlertCircle } from 'lucide-react';
import CustomDropdown from '../../../../../../../components/reusableComponents/CustomDropdown/CustomDropdown';
import styles from './TransformationParam.module.css';

const TransformationParam = ({ param, value, onChange, error, stepId }) => {
  const shared = {
    name: `${stepId}-${param.name}`,
    'aria-invalid': !!error,
    className: `${styles.input} ${error ? styles.error : ''}`
  };

  const handleChange = (newValue) => {
    // Pass the param name instead of 'value'
    onChange(param.name, newValue);
  };

  const renderInput = () => {
    if (param.type === 'select') {
      return (
        <CustomDropdown
          items={param.options}
          value={value}
          onChange={handleChange}
          placeholder={param.placeholder || `Select ${param.label}`}
          style={{ height: "2.375rem" }}
          multiSelect={param.multiSelect}
          {...shared}
          {...param.validation}
        />
      );
    }

    // Handle different value types
    const displayValue = value ?? '';

    return (
      <input
        type={param.type || 'text'}
        value={displayValue}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={param.placeholder}
        {...shared}
        {...param.validation}
      />
    );
  };

  return (
    <div className={styles.paramWrapper}>
      <label className={styles.label}>
        {param.label}
        {param.validation?.required && <span className={styles.required}>*</span>}
      </label>
      <div className={styles.inputContainer}>
        {renderInput()}
        {error && (
          <div className={styles.errorMessage}>
            <AlertCircle size={14} className={styles.errorIcon} />
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(TransformationParam);