// StaticValueContent.jsx
import React, { useState, useMemo } from 'react';
import { useHistory } from '../sharedHelpers/useHistory';
import ControlsSection from '../sharedHelpers/ControlsSection';
import styles from './StaticValueContent.module.css';

const StaticValueContent = ({ onClose, onSave, initialValue }) => {
  const [validationState, setValidationState] = useState({
    validationAttempted: false,
    isValid: false
  });

  const {
    current: value,
    updateHistory,
    undo,
    redo,
    resetChanges,
    canUndo,
    canRedo,
    hasChanges
  } = useHistory(initialValue || '');

  const isValidData = useMemo(() => 
    value.trim().length > 0
  , [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    updateHistory(newValue);
    setValidationState(prev => ({
      ...prev,
      isValid: newValue.trim().length > 0
    }));
  };

  const handleSave = () => {
    if (isValidData) {
      onSave?.({ staticValue: value });
      resetChanges();
    } else {
      setValidationState(prev => ({
        ...prev,
        validationAttempted: true
      }));
    }
  };

  return (
    <div className={styles.container}>
      <ControlsSection
        onUndo={undo}
        onRedo={redo}
        onSave={handleSave}
        onClose={onClose}
        canUndo={canUndo}
        canRedo={canRedo}
        isDisabled={!hasChanges || !isValidData}
      />
      
      <div className={styles.content}>
        <div className={styles.header}>
          <h3 className={styles.title}>
            Static Value Configuration<span className={styles.required}>*</span>
          </h3>
          <p className={styles.subtitle}>Define a static value for this field</p>
        </div>
        
        <div className={styles.inputSection}>
          <div className={styles.fieldLabel}>
            Static Value <span className={styles.required}>*</span>
          </div>
          <div className={styles.inputWrapper}>
            <input
              type="text"
              value={value}
              onChange={handleChange}
              className={`${styles.input} ${validationState.validationAttempted && !isValidData ? styles.inputError : ''}`}
              placeholder="Enter static value"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticValueContent;