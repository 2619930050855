import React from 'react';
import { motion } from 'framer-motion';
import { Trash2, ChevronDown, AlertCircle } from 'lucide-react';
import CustomDropdown from '../../../../../../../components/reusableComponents/CustomDropdown/CustomDropdown';
import TransformationParam from './TransformationParam';
import styles from './TransformationRow.module.css';

const TransformationRow = ({
  step,
  functionTypeOptions,
  transformationTypes = {}, 
  onChange,
  onDelete,
  errors = {},
  disabled = false,
  index = 0,
  disabledItems = []
}) => {
  const functionType = transformationTypes[step.function] || {};
  const params = functionType?.params?.sort((a, b) => a.order - b.order) || [];
  const hasFunctionError = Boolean(errors.function);

  const handleFunctionChange = (newFunction) => {
    onChange(step.id, 'function', newFunction);
  };

  const handleParamChange = (paramName, value) => {
    const updatedParams = {
      ...step.params,
      [paramName]: value
    };

    onChange(step.id, 'params', updatedParams);
  };

  return (
    <motion.div
      className={styles.wrapper}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={styles.row}>
        <div className={styles.leftContent}>
          <div className={styles.indexCell}>{index + 1}</div>
          <div className={styles.functionCell}>
            <label className={styles.label}>
              Function
              <span className={styles.required}>*</span>
            </label>
            <div className={styles.dropdownWrapper}>
              <CustomDropdown
                name={`${step.id}-function`}
                items={functionTypeOptions}
                value={step.function}
                onChange={handleFunctionChange}
                placeholder="Select function"
                disabled={disabled}
                icon={<ChevronDown size={16} className={styles.chevron} />}
                style={{ 
                  height: "2.375rem",
                  borderColor: hasFunctionError ? '#EF4444' : undefined 
                }}
                className={hasFunctionError ? styles.errorField : ''}
                disabledItems={disabledItems}
                required
                aria-invalid={hasFunctionError}
              />
              {hasFunctionError && (
                <div className={styles.errorMessage}>
                  <AlertCircle size={12} className={styles.errorIcon} />
                  <span>{errors.function}</span>
                </div>
              )}
            </div>
          </div>
          <div className={styles.parameterCells}>
            {params.map(param => (
              <TransformationParam
                key={param.name}
                param={param}
                value={step.params?.[param.name]}
                onChange={handleParamChange}
                error={errors[param.name]}
                stepId={step.id}
              />
            ))}
          </div>
        </div>
        <div className={styles.deleteCell}>
          <button
            onClick={() => onDelete(step.id)}
            className={styles.deleteButton}
            disabled={disabled}
            type="button"
            aria-label="Delete transformation"
          >
            <Trash2 size={16} />
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default React.memo(TransformationRow);