// export const fetchProcessFlowListApi = async (accessToken, params) => {
//     const apiUrl = `${process.env.REACT_APP_API_URL}/api/process-flow/list/`;

//     const response = await fetch(apiUrl, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${accessToken}`,
//         },
//         body: JSON.stringify(params),
//     });

//     if (!response.ok) {
//         throw new Error('Failed to fetch process flow list');
//     }

//     return await response.json();
// };

// export const fetchProcessFlowDetailApi = async (accessToken, id) => {
//     const apiUrl = `${process.env.REACT_APP_API_URL}/api/process-flow/detail/`;

//     const response = await fetch(apiUrl, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${accessToken}`,
//         },
//         body: JSON.stringify({ id }),
//     });

//     if (!response.ok) {
//         throw new Error('Failed to fetch process flow detail');
//     }

//     return await response.json();
// };


// api/processFlowApi.js

import { ApiError, handleResponse } from './apiResponseProcessor';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchProcessFlowListApi = async (accessToken, params, page = 1) => {
    try {
        const url = new URL(`${API_URL}/api/run_history_list/`);
        // Add page as query parameter
        url.searchParams.append('page', page);

        const response = await fetch(url.toString(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify(params),
        });
        return handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to fetch process flow list' }]);
    }
};

export const fetchProcessFlowDetailApi = async (accessToken, id) => {
    try {
        const response = await fetch(`${API_URL}/api/process-flow/detail/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ id }),
        });
        return handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to fetch process flow detail' }]);
    }
};

export const fetchStatusMasterApi = async (accessToken) => {
    try {
        const response = await fetch(`${API_URL}/api/run_history_status_master/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });
        return handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to fetch status options' }]);
    }
};