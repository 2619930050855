import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { Search, AlertTriangle, ChevronLeft, ChevronRight, Loader } from 'lucide-react';
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as SystemSettingsIcon } from "../../../../assetts/images/systemSettings.svg";
import CustomDropdown from "../../../../components/reusableComponents/CustomDropdown/CustomDropdown";
import { fetchProcessFlowListApi, fetchStatusMasterApi } from "../../../../api/processFlowApi";
import { filterLogs, getStatusIcon, getStatusStyles } from "./logUtils";
import EmptyState from "../../../../components/reusableComponents/EmptyState/EmptyState";
import styles from "./DetailViewSidebar.module.css";

const DetailViewSidebar = ({ onLogSelect }) => {
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [logs, setLogs] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(100);
  const [highlightedLog, setHighlightedLog] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(Date.now() - 86400000)); // Yesterday
  const [toDate, setToDate] = useState(new Date()); // Today
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);  
  const [totalLogs, setTotalLogs] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const response = await fetchStatusMasterApi(accessToken);
        if (response?.graph_status_choices) {
          setStatusOptions(response.graph_status_choices);
        }
      } catch (err) {
        console.error("Error fetching status options:", err);
        setError("Error loading status options. Please refresh the page.");
      }
    };

    fetchStatusOptions();
  }, [accessToken]);

  useEffect(() => {
    const fetchLogs = async () => {
      if (isLoading) return;
      
      setIsLoading(true);
      try {
        const logsData = await fetchProcessFlowListApi(accessToken, {
          from_date: fromDate.toISOString().split('T')[0],
          to_date: toDate.toISOString().split('T')[0],
          status: selectedStatus,
        }, currentPage);
        
        setLogs(logsData.results || []);
        setTotalLogs(logsData.count || 0);
        setHasNextPage(!!logsData.next);
        setHasPreviousPage(!!logsData.previous);
        setError(null);
        
        if (onLogSelect && logsData?.results?.[0]) {
          onLogSelect(logsData.results[0]);
        }
      } catch (err) {
        setError("Error fetching logs. Please try again later.");
        console.error("Error fetching logs:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLogs();
  }, [accessToken, selectedStatus, fromDate, toDate, currentPage, onLogSelect]);

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleLogClick = (log) => {
    setHighlightedLog(log.id);
    if (onLogSelect) onLogSelect(log);
  };

  const filteredLogs = filterLogs(logs, selectedStatus);

  const renderListContent = () => {
    if (error) {
      return (
        <EmptyState
          icon={AlertTriangle}
          title="Error Loading Logs"
          message={error}
          iconSize={18}
          className={`${styles.sidebarEmptyState} ${styles.errorState}`}
        />
      );
    }

    if (isLoading) {
      return (
        <div className={styles.loadingState}>
          <Loader className={styles.loadingIcon} size={24} />
          <span>Loading logs...</span>
        </div>
      );
    }

    if (filteredLogs.length === 0) {
      const currentStatusLabel = statusOptions.find(opt => opt.value === selectedStatus)?.label || 'All';
      const message = selectedStatus !== '' 
        ? `No logs found with status "${currentStatusLabel}" for the selected date range`
        : 'No logs found for the selected date range';

      return (
        <EmptyState
          icon={Search}
          title="No Logs Found"
          message={message}
          iconSize={18}
          className={styles.sidebarEmptyState}
        />
      );
    }

    return (
      <AnimatePresence mode="wait">
        <motion.div 
          className={styles.logList}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {filteredLogs.map((log) => (
            <motion.div
              key={log.id}
              layout
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              className={`${styles.logItem} ${highlightedLog === log.id ? styles.highlighted : ""}`}
              onClick={() => handleLogClick(log)}
              style={getStatusStyles(log.status)}
            >
              <div className={styles.dateTime}>
                <span className={`${styles.logDate} ${highlightedLog === log.id ? styles.highlighted : ""}`}>
                  {log.date}
                </span>
                <span className={`${styles.logTime} ${highlightedLog === log.id ? styles.highlighted : ""}`}>
                  {log.time}
                </span>
              </div>
              {getStatusIcon(log.status, styles)}
            </motion.div>
          ))}
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.filterSection}>
        <div className={styles.filterItem}>
          <div className={styles.filterHeader}>
            <SystemSettingsIcon className={styles.filterIcon} />
            <span className={styles.filterTitle}>Date*</span>
          </div>
          <div className={styles.datePickerContainer}>
            <DatePicker
              selected={fromDate}
              onChange={date => setFromDate(date)}
              className={styles.dateInput}
              dateFormat="yyyy-MM-dd"
              placeholderText="From date"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              todayButton="Today"
              isClearable
            />
            <span className={styles.dateSeparator}>to</span>
            <DatePicker
              selected={toDate}
              onChange={date => setToDate(date)}
              className={styles.dateInput}
              dateFormat="yyyy-MM-dd"
              placeholderText="To date"
              minDate={fromDate}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              todayButton="Today"
              isClearable
            />
          </div>
        </div>

        <div className={styles.filterItem}>
          <div className={styles.filterHeader}>
            <SystemSettingsIcon className={styles.filterIcon} />
            <span className={styles.filterTitle}>Status*</span>
          </div>
          <CustomDropdown
            items={statusOptions}
            value={selectedStatus}
            onChange={handleStatusChange}
            style={{
              width: "100%",
              maxWidth: "261px",
              height: "53px",
              borderRadius: "12px",
              fontSize: "18.97px",
              lineHeight: "22.96px",
              color: "#6B6B6B",
            }}
          />
        </div>
      </div>
      <div className={styles.listSection}>
        {renderListContent()}
        
        {(hasPreviousPage || hasNextPage) && !isLoading && (
          <motion.div 
            className={styles.paginationContainer}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <div className={styles.paginationButtons}>
              <motion.button
                className={styles.paginationButton}
                onClick={() => setCurrentPage(prev => prev - 1)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                disabled={!hasPreviousPage}
                title="Previous page"
              >
                <ChevronLeft size={20} />
              </motion.button>

              <span className={styles.paginationInfo}>{currentPage}</span>

              <motion.button
                className={styles.paginationButton}
                onClick={() => setCurrentPage(prev => prev + 1)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                disabled={!hasNextPage}
                title="Next page"
              >
                <ChevronRight size={20} />
              </motion.button>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default DetailViewSidebar;