// FieldMappingContent.jsx
import React, { useState, useEffect, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import Header from "./Header/Header";
import ActionBar from "./ActionBar/ActionBar";
import MappingFieldsList from "./MappingFieldsList/MappingFieldsList";
import { 
  initialState, 
  stateUpdates,
  mappingOperations,
  apiOperations
} from './helpers';
import styles from "./FieldMappingContent.module.css";

const FieldMappingContent = memo(({ sourceNode, currentNode, onMappingChange, initialMappingId }) => {
  const accessToken = useSelector(state => state.auth.accessToken);
  const [state, setState] = useState({
    selectedMapping: initialMappingId || 'new',
    mappingFields: initialState.fields,
    initialState,
    hasChanges: false,
    mappingName: 'New Mapping',
    existingMappings: [],
    successPopup: false,
    errorPopup: false,
    errorMessage: ''
  });

  // Keep track of current mapping ID separately from state to persist during saves
  const [currentMappingId, setCurrentMappingId] = useState(initialMappingId || 'new');

  useEffect(() => {
    apiOperations.fetch({
      sourceNode,
      currentNode,
      accessToken,
      initialMappingId: currentMappingId, // Use currentMappingId instead of initialMappingId
      setState
    });
  }, [sourceNode, currentNode, accessToken, currentMappingId]);

  const handlers = {
    closePopup: () => stateUpdates.handleSnackbar('', setState),
    nameChange: newName => setState(prev => ({ ...prev, mappingName: newName, hasChanges: true })),
    reset: () => setState(prev => mappingOperations.getInitialState(prev)),
    ...stateUpdates.handleMappingOperations(setState),
    save: async () => {
      const result = await apiOperations.save({
        state,
        sourceNode,
        currentNode,
        accessToken,
        onMappingChange,
        setState: (updater) => {
          setState(prev => {
            const newState = typeof updater === 'function' ? updater(prev) : updater;
            // Ensure we keep the current mapping ID during state updates
            return {
              ...newState,
              selectedMapping: currentMappingId
            };
          });
        },
        onError: msg => stateUpdates.handleSnackbar('error', setState, msg)
      });

      // If save was successful and returned a new mapping ID, update currentMappingId
      if (result?.mappingId) {
        setCurrentMappingId(result.mappingId);
      }
    }
  };

  const handleMappingTypeChange = useCallback(value => {
    setCurrentMappingId(value); // Update currentMappingId when mapping changes
    setState(prev => mappingOperations.getInitialState(prev, value));
  }, []);

  const isSaveDisabled = state.mappingFields.some(field => {
    if (field.mappingType === 'static') {
      return !field.targetField;
    }
    return !field.sourceField || !field.targetField || !field.sourceDataType || !field.targetDataType;
  });

  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <div className={styles.headerContent}>
          <Header
            selectedMapping={currentMappingId} // Use currentMappingId instead of state.selectedMapping
            existingMappings={state.existingMappings}
            onMappingTypeChange={handleMappingTypeChange}
            onNameChange={handlers.nameChange}
            mappingName={state.mappingName}
          />
          <ActionBar 
            onSave={handlers.save}
            onReset={handlers.reset}
            disabled={isSaveDisabled}
          />
        </div>
      </div>

      <div className={styles.mainContent}>
        <div className={styles.mappingContainer}>
          <MappingFieldsList
            mappingFields={state.mappingFields}
            sourceNode={sourceNode}
            currentNode={currentNode}
            onFieldMappingChange={handlers.fieldMappingChange}
            onDelete={handlers.deleteField}
            onReorder={handlers.reorderFields}
            onAdd={handlers.addField}
          />
        </div>
      </div>

      {['success', 'error'].map(type => (
        <Snackbar
          key={type}
          open={state[`${type}Popup`]}
          autoHideDuration={3000}
          onClose={handlers.closePopup}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handlers.closePopup} severity={type} sx={{ width: "100%" }}>
            {type === 'success' ? 'Mapping saved successfully!' : state.errorMessage}
          </Alert>
        </Snackbar>
      ))}
    </div>
  );
});

FieldMappingContent.displayName = 'FieldMappingContent';

export default FieldMappingContent;