import React from "react";
import { Handle, Position, useReactFlow } from "@xyflow/react";
import { useSelector } from "react-redux";
import styles from "./NodeStyles.module.css";
import { motion } from "framer-motion";
import { X } from "lucide-react";

const GenericNode = ({ data, id }) => {
  const { properties, runDetails } = data?.dynamic || {};
  const { label, icon, styles: nodeStyles } = properties || {};
  
  const { setNodes,setEdges } = useReactFlow();
  const mode = useSelector((state) => state.workflow.mode);

  const status = runDetails?.info?.status || "default";

  // Define color based on status
  const getStatusBorderColor = (status) => {
    switch (status) {
      case "success":
        return "#62E4B5";
      case "warning":
        return "#E27DDD";
      case "pending":
        return "#E4C554";
      case "error":
        return "#EF6A6A";
      default:
        return null;
    }
  };

  const borderColor = getStatusBorderColor(status);

  const handleRemoveNode = (event) => {
    event.stopPropagation();
    
    // Remove the node
    setNodes((nodes) => nodes.filter((node) => node.id !== id));
    
    // Remove any edges connected to this node
    setEdges((edges) => edges.filter(
      (edge) => edge.source !== id && edge.target !== id
    ));
  };

  return (
    <div className={styles.wrapper}>
      {mode === "edit" && (
        

        <motion.button
        className={styles.removeButton}
        onClick={handleRemoveNode}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
              <X size={12} strokeWidth={2.5} />
            </motion.button>
      )}
      <div
        className={styles.nodeContainer}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          boxShadow: `0 0 0 2px #FFF, 0 0 0 4px ${borderColor}`,
          backgroundColor: nodeStyles?.background || "#fff",
        }}
      >
        {
          <img
            src={data?.dynamic?.properties.icon}
            alt={`icon`}
            className={styles.nodeIcon}
          />
        }
        <Handle
          type="target"
          position={Position.Top}
          id={`${id}-top`}
          className={`${styles.nodeHandle} ${styles.handleTop}`}
          isConnectable={true}
        />
        <Handle
          type="target"
          position={Position.Left}
          id={`${id}-left`}
          className={`${styles.nodeHandle} ${styles.handleLeft}`}
          isConnectable={true}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id={`${id}-bottom`}
          className={`${styles.nodeHandle} ${styles.handleBottom}`}
          isConnectable={true}
        />
        <Handle
          type="source"
          position={Position.Right}
          id={`${id}-right`}
          className={`${styles.nodeHandle} ${styles.handleRight}`}
          isConnectable={true}
        />
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default GenericNode;
