// // api.js or EmailVerificationApi.js
// export const verifyEmailApi = async (code, token) => {
//     const apiUrl = `${process.env.REACT_APP_API_URL}/api/verify-email/${code}/${token}/`;
  
//     const response = await fetch(apiUrl, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
  
//     if (!response.ok) {
//       throw new Error('Email verification failed');
//     }
  
//     return response;
//   };
  
// api/emailVerificationApi.js

import { ApiError, handleResponse } from './apiResponseProcessor';

const API_URL = process.env.REACT_APP_API_URL;

export const verifyEmailApi = async (code, token) => {
    try {
        const response = await fetch(`${API_URL}/api/verify-email/${code}/${token}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Email verification failed' }]);
    }
};