// LookupContent.jsx
import React, { useState, useCallback, useMemo } from 'react';
import { Trash2, MoveRight, ArrowLeftRight, Plus } from 'lucide-react';
import { useHistory } from '../sharedHelpers/useHistory';
import EmptyState from '../../../../../../../components/reusableComponents/EmptyState/EmptyState';
import ControlsSection from '../sharedHelpers/ControlsSection';
import styles from './LookupContent.module.css';

const LookupRow = ({ step, index, onDelete, onChange, showError }) => (
  <div className={styles.stepRow}>
    <div className={styles.inputWrapper}>
      <input
        type="text"
        value={step.source}
        onChange={(e) => onChange(index, 'source', e.target.value)}
        className={`${styles.input} ${showError ? styles.inputError : ''}`}
        placeholder="Enter source value"
      />
    </div>
    <div className={styles.arrowWrapper}>
      <MoveRight size={18} className={styles.arrow} />
    </div>
    <div className={styles.inputGroup}>
      <input
        type="text"
        value={step.destination}
        onChange={(e) => onChange(index, 'destination', e.target.value)}
        className={`${styles.input} ${showError ? styles.inputError : ''}`}
        placeholder="Enter destination value"
      />
    </div>
    <button
      onClick={() => onDelete(index)}
      className={styles.deleteButton}
      title="Delete step"
    >
      <Trash2 size={16} />
    </button>
  </div>
);

const LookupContent = ({ onClose, onSave, initialConfig }) => {
  const [validationState, setValidationState] = useState({
    validationAttempted: false,
    invalidFields: new Set()
  });

  const {
    current: steps,
    updateHistory,
    undo,
    redo,
    resetChanges,
    canUndo,
    canRedo,
    hasChanges
  } = useHistory(initialConfig?.steps || []);

  const isValidData = useMemo(() => 
    steps.every(step => step.source?.trim() && step.destination?.trim())
  , [steps]);

  const updateValidationState = useCallback((newSteps, index = null) => {
    setValidationState(prev => {
      if (!prev.validationAttempted) return prev;

      const newInvalidFields = new Set(prev.invalidFields);
      
      if (index !== null) {
        const step = newSteps[index];
        if (!step.source?.trim() || !step.destination?.trim()) {
          newInvalidFields.add(index);
        } else {
          newInvalidFields.delete(index);
        }
      } else {
        newSteps.forEach((step, idx) => {
          if (!step.source?.trim() || !step.destination?.trim()) {
            newInvalidFields.add(idx);
          } else {
            newInvalidFields.delete(idx);
          }
        });
      }

      return {
        ...prev,
        invalidFields: newInvalidFields
      };
    });
  }, []);

  const handleAdd = () => {
    const emptyFieldIndexes = steps
      .map((step, index) => (!step.source?.trim() || !step.destination?.trim() ? index : -1))
      .filter(index => index !== -1);

    if (emptyFieldIndexes.length > 0) {
      setValidationState({
        validationAttempted: true,
        invalidFields: new Set(emptyFieldIndexes)
      });
      return;
    }

    const newSteps = [...steps, { source: '', destination: '' }];
    updateHistory(newSteps);
    setValidationState({
      validationAttempted: false,
      invalidFields: new Set()
    });
  };

  const handleDelete = (index) => {
    const newSteps = steps.filter((_, i) => i !== index);
    updateHistory(newSteps);
    updateValidationState(newSteps);
  };

  const handleChange = (index, field, value) => {
    const newSteps = steps.map((step, i) => 
      i === index ? { ...step, [field]: value } : step
    );
    updateHistory(newSteps);
    updateValidationState(newSteps, index);
  };

  const handleSave = () => {
    if (isValidData) {
      onSave?.({ steps });
      resetChanges();
      setValidationState({
        validationAttempted: false,
        invalidFields: new Set()
      });
    } else {
      setValidationState(prev => ({
        validationAttempted: true,
        invalidFields: new Set(
          steps
            .map((step, index) => (!step.source?.trim() || !step.destination?.trim() ? index : -1))
            .filter(index => index !== -1)
        )
      }));
    }
  };

  const shouldShowError = useCallback((step, index) => (
    validationState.validationAttempted && 
    validationState.invalidFields.has(index) && 
    (!step.source?.trim() || !step.destination?.trim())
  ), [validationState]);

  const showEmptyState = steps.length === 0;

  return (
    <div className={styles.container}>
      <ControlsSection
        onAdd={handleAdd}
        onUndo={undo}
        onRedo={redo}
        onSave={handleSave}
        onClose={onClose}
        canUndo={canUndo}
        canRedo={canRedo}
        isDisabled={!hasChanges || !isValidData}
        showAddButton={showEmptyState}
      />
      
      <div className={styles.content}>
        {showEmptyState ? (
          <EmptyState 
            icon={ArrowLeftRight}
            title="No steps configured"
            message="Click the Add button to create a step"
          />
        ) : (
          <>
            <div>
              <h3 className={styles.mainTitle}>
                Lookup Rules<span className={styles.required}>*</span>
              </h3>
              <p className={styles.subtitle}>Configure lookup rules for value mapping</p>
            </div>

            <div className={styles.header}>
              <div className={styles.headerTitle}>
                Source Values <span className={styles.required}>*</span>
              </div>
              <div className={styles.headerTitle}>
                Destination Values <span className={styles.required}>*</span>
              </div>
            </div>
            
            <div className={styles.steps}>
              {steps.map((step, index) => (
                <LookupRow
                  key={index}
                  step={step}
                  index={index}
                  onDelete={handleDelete}
                  onChange={handleChange}
                  showError={shouldShowError(step, index)}
                />
              ))}
            </div>
            <button
              onClick={handleAdd}
              className={styles.addButton}
              title="Add new step"
            >
              <Plus size={16} />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default LookupContent;