import React, { memo, useMemo, useState, useEffect } from "react";
import { Edit2, Check, X } from 'lucide-react';
import CustomDropdown from "../../../../../components/reusableComponents/CustomDropdown/CustomDropdown";
import styles from "./Header.module.css";

const Header = memo(({ 
  selectedMapping,
  existingMappings = {},
  onMappingTypeChange,
  onNameChange,
  mappingName,
}) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState(mappingName);

  useEffect(() => {
    if (!isEditingName) {
      if (selectedMapping === 'new' && !mappingName) {
        setEditedName('New Mapping Name');
      } else {
        setEditedName(mappingName);
      }
    }
  }, [selectedMapping, mappingName, isEditingName]);

  const dropdownItems = useMemo(() => {
    const baseItems = [{ value: 'new', label: 'Create New Mapping' }];
    const mappingsArray = Object.entries(existingMappings)
      .filter(([key, value]) => key !== 'source' && typeof value === 'object')
      .map(([_, mapping]) => ({
        value: mapping.id,
        label: mapping.name || `Mapping ${mapping.id}`
      }));
    return [...baseItems, ...mappingsArray];
  }, [existingMappings]);

  

  const handleSaveName = () => {
    if (editedName.trim()) {
      onNameChange(editedName.trim());
      setIsEditingName(false);
    }
  };

  const handleCancelEdit = () => {
    setEditedName(mappingName);
    setIsEditingName(false);
  };

  return (
    <div className={styles.header}>
      <div className={styles.mappingName}>
        {isEditingName ? (
          <div className={styles.editContainer}>
            <input
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              className={styles.nameInput}
              autoFocus
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleSaveName();
                if (e.key === 'Escape') handleCancelEdit();
              }}
            />
            <button onClick={handleSaveName} className={styles.iconButton} title="Save">
              <Check size={16} />
            </button>
            <button onClick={handleCancelEdit} className={styles.iconButton} title="Cancel">
              <X size={16} />
            </button>
          </div>
        ) : (
          <div className={styles.viewContainer}>
            <h3 className={styles.mappingTitle}>{editedName}</h3>
            <button 
              onClick={() => setIsEditingName(true)} 
              className={styles.iconButton}
              title="Edit name"
            >
              <Edit2 size={16} />
            </button>
          </div>
        )}
      </div>
      <div className={styles.rightSection}>
        <div className={styles.mappingSelector}>
          <CustomDropdown
            items={dropdownItems}
            value={selectedMapping}
            onChange={onMappingTypeChange}
            multiSelect={false}
            style={{
              height: '34px',
              boxSizing: 'border-box',
              borderRadius: '8px'
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default Header;