import { ReactComponent as WarningIcon } from "../../../../assetts/images/warning.svg";
import { ReactComponent as SuccessIcon } from "../../../../assetts/images/success.svg";
import { ReactComponent as ErrorIcon } from "../../../../assetts/images/error.svg";
import { ReactComponent as PendingIcon } from "../../../../assetts/images/pending.svg";
import React from 'react';

/**
 * Filter logs based on the selected status
 * Returns all logs if no status is selected (empty string)
 */
export const filterLogs = (logs, selectedStatus) => {
  if (selectedStatus === 100) return logs;
  return logs.filter((log) => log.status === selectedStatus);
};

/**
 * Get the appropriate status icon based on status value
 * 0: Ready        - Pending icon
 * 1: In Progress  - Pending icon
 * 2: Paused       - Warning icon
 * 3: Skipped      - Warning icon
 * 4: Completed    - Success icon
 * 5: Failed       - Error icon
 * 6: Warning      - Warning icon
 * 7: Waiting      - Pending icon
 * 8: Terminated   - Error icon
 */
export const getStatusIcon = (status, styles) => {
  switch (status) {
    case 4:
      return <SuccessIcon className={styles.statusIcon} />;
    case 5:
    case 8:
      return <ErrorIcon className={styles.statusIcon} />;
    case 2:
    case 3:
    case 6:
      return <WarningIcon className={styles.statusIcon} />;
    case 0:
    case 1:
    case 7:
    default:
      return <PendingIcon className={styles.statusIcon} />;
  }
};

/**
 * Get the appropriate border color style based on status value
 * 0: Ready        - Yellow (Pending)
 * 1: In Progress  - Yellow (Pending)
 * 2: Paused       - Purple (Warning)
 * 3: Skipped      - Purple (Warning)
 * 4: Completed    - Green (Success)
 * 5: Failed       - Red (Error)
 * 6: Warning      - Purple (Warning)
 * 7: Waiting      - Yellow (Pending)
 * 8: Terminated   - Red (Error)
 */
export const getStatusStyles = (status) => {
  switch (status) {
    case 4:
      return { "--border-color": "#62E4B5" };  // Green for success
    case 5:
    case 8:
      return { "--border-color": "#EF6A6A" };  // Red for error states
    case 2:
    case 3:
    case 6:
      return { "--border-color": "#E27DDD" };  // Purple for warning states
    case 0:
    case 1:
    case 7:
    default:
      return { "--border-color": "#E4C554" };  // Yellow for pending states
  }
};