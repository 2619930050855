import React, { memo } from 'react';
import { Plus, Undo2, Redo2, Check, X } from 'lucide-react';
import styles from './ControlsSection.module.css';

const ControlsSection = memo(({
  onAdd,
  onUndo,
  onRedo,
  onSave,
  onClose,
  canUndo,
  canRedo,
  isDisabled,
  showAddButton = true
}) => (
  <div className={styles.headerContainer}>
    <div className={styles.mainContent}>
      <div className={styles.actionsGroup}>
        <div className={styles.historyActions}>
          <button
            type="button"
            onClick={onUndo}
            disabled={!canUndo}
            className={styles.iconButton}
            title="Undo"
          >
            <Undo2 size={16} />
          </button>
          <button
            type="button"
            onClick={onRedo}
            disabled={!canRedo}
            className={styles.iconButton}
            title="Redo"
          >
            <Redo2 size={16} />
          </button>
        </div>

        <div className={styles.mainActions}>
          {onAdd && showAddButton && (
            <button type="button" onClick={onAdd} className={styles.addBotton}>
              <Plus size={16} />
            </button>
          )}
          <button
            type="button"
            onClick={onClose}
            className={styles.secondaryButton}
          >
            <X size={16} />
            <span>Cancel</span>
          </button>
          <button
            type="button"
            onClick={onSave}
            disabled={isDisabled}
            className={styles.primaryButton}
          >
            <Check size={16} />
            <span>Apply Changes</span>
          </button>
        </div>
      </div>
    </div>
  </div>
));

export default ControlsSection;