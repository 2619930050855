// utils.js
export const normalizePath = (path) => {
  if (path === null || path === undefined) return '';
  // Convert to string to handle numbers
  const pathStr = String(path);
  return pathStr
    .toLowerCase()
    .replace(/name$/i, '')
    .split('.')
    .map(segment => segment.trim())
    .join('.');
};

export const searchInItems = (items, searchText) => {
  const searchLower = searchText.toLowerCase();
  
  const searchItem = (item) => {
    // Convert values to strings for comparison
    const itemLabel = String(item.label).toLowerCase();
    const itemValue = String(item.value).toLowerCase();
    
    const matchesSearch = itemLabel.includes(searchLower) ||
                         itemValue.includes(searchLower);
    
    if (item.children) {
      const matchingChildren = item.children
        .map(child => searchItem(child))
        .filter(Boolean);
      
      if (matchingChildren.length > 0) {
        return { ...item, children: matchingChildren, expanded: true };
      }
    }
    return matchesSearch ? item : null;
  };

  return items.map(item => searchItem(item)).filter(Boolean);
};