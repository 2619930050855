import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, AlertCircle, CheckCircle, AlertTriangle, Info, Loader } from 'lucide-react';
import styles from './ConfirmationDialog.module.css';

// Default configurations
const DEFAULT_CONFIG = {
  variants: {
    danger: {
      icon: AlertCircle,
      className: styles.danger,
      confirmButtonClass: styles.dangerButton,
      color: '#ef4444'
    },
    success: {
      icon: CheckCircle,
      className: styles.success,
      confirmButtonClass: styles.successButton,
      color: '#22c55e'
    },
    warning: {
      icon: AlertTriangle,
      className: styles.warning,
      confirmButtonClass: styles.warningButton,
      color: '#f59e0b'
    },
    info: {
      icon: Info,
      className: styles.info,
      confirmButtonClass: styles.infoButton,
      color: '#3b82f6'
    }
  },
  animations: {
    overlay: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
      transition: { duration: 0.2 }
    },
    dialog: {
      initial: { opacity: 0, scale: 0.95, y: 20 },
      animate: { opacity: 1, scale: 1, y: 0 },
      exit: { opacity: 0, scale: 0.95, y: 20 },
      transition: { type: "spring", stiffness: 300, damping: 30 }
    },
    icon: {
      initial: { scale: 0 },
      animate: { scale: 1 },
      transition: { type: "spring", stiffness: 400, damping: 17 }
    },
    content: {
      initial: { opacity: 0, y: 10 },
      animate: { opacity: 1, y: 0 },
      transition: { delay: 0.1 }
    },
    actions: {
      initial: { opacity: 0, y: 10 },
      animate: { opacity: 1, y: 0 },
      transition: { delay: 0.2 }
    }
  }
};

const ConfirmationDialog = ({
  // Required props with default values
  isOpen = false,
  onClose = () => {},
  onCancel = () => {},
  onConfirm = () => {},
  
  // Optional configuration props with defaults
  variant = 'danger',
  title = 'Confirm Action',
  message = 'Are you sure you want to proceed?',
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  showIcon = true,
  position = 'center',
  size = 'medium',
  closeOnOverlayClick = true,
  closeOnEscape = true,
  blurBackground = true,
  customIcon = null,
  className = '',
  isConfirmLoading = false,
  isConfirmDisabled = false,
  
  // Additional optional props
  showCloseButton = true,
  confirmButtonClassName = '',
  cancelButtonClassName = '',
  contentClassName = '',
  overlayClassName = '',
  preventCloseOnLoading = true,
  customAnimations = {},
}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const selectedVariant = DEFAULT_CONFIG.variants[variant] || DEFAULT_CONFIG.variants.info;
  const IconComponent = customIcon || selectedVariant.icon;

  // Merge custom animations with defaults
  const animations = {
    ...DEFAULT_CONFIG.animations,
    ...customAnimations
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (closeOnEscape && !isConfirmLoading && !isConfirming) {
        if (e.key === 'Escape') onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose, closeOnEscape, isConfirmLoading, isConfirming]);

  const handleConfirm = async () => {
    try {
      setIsConfirming(true);
      await onConfirm();
    } catch (error) {
      console.error('Confirmation action failed:', error);
    } finally {
      setIsConfirming(false);
    }
  };

  const handleOverlayClick = () => {
    if (closeOnOverlayClick && !isConfirmLoading && !isConfirming) {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className={styles.wrapper} role="dialog" aria-modal="true">
          <motion.div
            {...animations.overlay}
            className={`${styles.overlay} ${blurBackground ? styles.blur : ''} ${overlayClassName}`}
            onClick={handleOverlayClick}
          />
          
          <motion.div
            {...animations.dialog}
            className={`
              ${styles.dialog} 
              ${styles[size]} 
              ${styles[position]} 
              ${selectedVariant.className}
              ${className}
            `}
          >
            {showCloseButton && (
              <button 
                className={styles.closeButton}
                onClick={onClose}
                disabled={preventCloseOnLoading && (isConfirmLoading || isConfirming)}
                aria-label="Close dialog"
              >
                <X className={styles.closeIcon} />
              </button>
            )}

            <div className={`${styles.content} ${contentClassName}`}>
              {showIcon && (
                <motion.div 
                  {...animations.icon}
                  className={styles.iconWrapper}
                  style={{ backgroundColor: `${selectedVariant.color}15` }}
                >
                  <IconComponent 
                    className={styles.icon} 
                    style={{ color: selectedVariant.color }}
                  />
                </motion.div>
              )}

              <motion.div
                {...animations.content}
                className={styles.textContent}
              >
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.message}>{message}</p>
              </motion.div>

              <motion.div 
                {...animations.actions}
                className={styles.actions}
              >
                <button 
                  className={`${styles.cancelButton} ${cancelButtonClassName}`}
                  onClick={onCancel}
                  disabled={preventCloseOnLoading && (isConfirmLoading || isConfirming)}
                >
                  {cancelText}
                </button>
                <button 
                  className={`
                    ${styles.confirmButton} 
                    ${selectedVariant.confirmButtonClass}
                    ${confirmButtonClassName}
                  `}
                  onClick={handleConfirm}
                  disabled={isConfirmLoading || isConfirmDisabled || isConfirming}
                >
                  {isConfirmLoading || isConfirming ? (
                    <span className={styles.loadingWrapper}>
                      <Loader className={styles.loadingIcon} />
                      Loading...
                    </span>
                  ) : confirmText}
                </button>
              </motion.div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ConfirmationDialog;