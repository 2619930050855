// // api/integrationApi.js

// export const fetchIntegrationTilesApi = async (accessToken, params, url = null) => {
//     const baseUrl = url || `${process.env.REACT_APP_API_URL}/api/integration-list/`;

//     const response = await fetch(baseUrl, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${accessToken}`,
//         },
//         body: JSON.stringify(params),
//     });

//     if (!response.ok) {
//         throw new Error('Failed to fetch integration tiles');
//     }

//     return await response.json();
// };

// export const fetchTagsApi = async (accessToken) => {
//     const apiUrl = `${process.env.REACT_APP_API_URL}/api/tag-list/`;

//     const response = await fetch(apiUrl, {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${accessToken}`,
//         },
//     });

//     if (!response.ok) {
//         throw new Error('Failed to fetch tags');
//     }

//     return await response.json();
// };

// api/integrationApi.js

import { ApiError, handleResponse } from './apiResponseProcessor';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchIntegrationTilesApi = async (accessToken, params, url = null) => {
    try {
        const baseUrl = url || `${API_URL}/api/integration-list/`;
        const response = await fetch(baseUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify(params),
        });
        return handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to fetch integration tiles' }]);
    }
};

export const fetchTagsApi = async (accessToken) => {
    try {
        const response = await fetch(`${API_URL}/api/tag-list/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });
        return handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to fetch tags' }]);
    }
};
