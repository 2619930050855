// api/mappingTypeApi.js

import { ApiError, handleResponse } from './apiResponseProcessor';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchValidationTypes = async (accessToken) => {
    try {
        const response = await fetch(`${API_URL}/api/validation/list`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            }
        });
        return handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to fetch validation types' }]);
    }
};

export const fetchTransformationTypes = async (accessToken) => {
    try {
        const response = await fetch(`${API_URL}/api/transformation/list`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            }
        });
        return handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to fetch transformation types' }]);
    }
};