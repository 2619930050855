// api/workflowMappingApi.js

import { ApiError, handleResponse } from './apiResponseProcessor';

const API_URL = process.env.REACT_APP_API_URL;

export const createWorkflowMapping = async (accessToken, params) => {
    try {
        const response = await fetch(`${API_URL}/api/user_workflow-mapping-create/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                name: params.name,
                mapping_schema: params.mapping_schema || {},
                source_task: params.source_task,
                destination_task: params.destination_task,
                workflow: params.workflow_id
            }),
        });

        return await handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to create workflow mapping' }]);
    }
};

export const listWorkflowMappings = async (accessToken, params) => {
    try {
        const response = await fetch(`${API_URL}/api/user_workflow-mapping-list/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                source_task: params.source_task,
                destination_task: params.destination_task,
                workflow_id: params.workflow_id
            }),
        });

        return await handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to fetch workflow mappings' }]);
    }
};

export const updateWorkflowMapping = async (accessToken, mappingId, params) => {
    try {
        const response = await fetch(`${API_URL}/api/user_workflow-mapping-update/${mappingId}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                name: params.name,
                mapping_schema: params.mapping_schema || {},
                source_task: params.source_task,
                destination_task: params.destination_task,
                workflow: params.workflow_id
            }),
        });

        return await handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to update workflow mapping' }]);
    }
};