// useHistory.js
import { useState, useCallback } from 'react';

export const useHistory = (initialState) => {
  const [state, setState] = useState(() => ({
    current: initialState,
    history: [initialState],
    currentIndex: 0,
    hasChanges: false
  }));

  const updateHistory = useCallback((newValue) => {
    setState(prev => {
      const newHistory = prev.history.slice(0, prev.currentIndex + 1);
      return {
        current: newValue,
        history: [...newHistory, newValue],
        currentIndex: newHistory.length,
        hasChanges: true
      };
    });
  }, []);

  const undo = useCallback(() => {
    if (state.currentIndex > 0) {
      setState(prev => ({
        ...prev,
        current: prev.history[prev.currentIndex - 1],
        currentIndex: prev.currentIndex - 1,
        hasChanges: true
      }));
    }
  }, [state.currentIndex]);

  const redo = useCallback(() => {
    if (state.currentIndex < state.history.length - 1) {
      setState(prev => ({
        ...prev,
        current: prev.history[prev.currentIndex + 1],
        currentIndex: prev.currentIndex + 1,
        hasChanges: true
      }));
    }
  }, [state.currentIndex, state.history.length]);

  const resetChanges = useCallback(() => {
    setState(prev => ({
      ...prev,
      hasChanges: false
    }));
  }, []);

  return {
    current: state.current,
    updateHistory,
    undo,
    redo,
    resetChanges,
    canUndo: state.currentIndex > 0,
    canRedo: state.currentIndex < state.history.length - 1,
    hasChanges: state.hasChanges
  };
};