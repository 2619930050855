// const API_BASE_URL = process.env.REACT_APP_API_URL;

// export const fetchNotificationsApi = async (accessToken) => {
//     const response = await fetch(`${API_BASE_URL}/api/notification-read-list`, {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${accessToken}`,
//         },
//     });

//     if (!response.ok) {
//         throw new Error('Failed to fetch notifications');
//     }

//     return await response.json();
// };

// export const markNotificationsAsReadApi = async (accessToken, notificationIds) => {
//     const response = await fetch(`${API_BASE_URL}/api/notification-bulk-mark-read/`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${accessToken}`,
//         },
//         body: JSON.stringify({ notification_ids: notificationIds }),
//     });

//     if (!response.ok) {
//         throw new Error('Failed to mark notifications as read');
//     }

//     return await response.json();
// };

// export const markNotificationsAsUnreadApi = async (accessToken, notificationIds) => {
//     const response = await fetch(`${API_BASE_URL}/api/notification-bulk-mark-unread/`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${accessToken}`,
//         },
//         body: JSON.stringify({ notification_ids: notificationIds }),
//     });

//     if (!response.ok) {
//         throw new Error('Failed to mark notifications as unread');
//     }

//     return await response.json();
// };

// export const deleteNotificationsApi = async (accessToken, notificationIds) => {
//     const response = await fetch(`${API_BASE_URL}/api/notification-bulk-delete/`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${accessToken}`,
//         },
//         body: JSON.stringify({ notification_ids: notificationIds }),
//     });

//     if (!response.ok) {
//         throw new Error('Failed to delete notifications');
//     }

//     return await response.json();
// };

// api/notificationApi.js

import { ApiError, handleResponse } from './apiResponseProcessor';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchNotificationsApi = async (accessToken) => {
    try {
        const response = await fetch(`${API_URL}/api/notification-read-list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });
        return handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to fetch notifications' }]);
    }
};

export const markNotificationsAsReadApi = async (accessToken, notificationIds) => {
    try {
        const response = await fetch(`${API_URL}/api/notification-bulk-mark-read/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ notification_ids: notificationIds }),
        });
        return handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to mark notifications as read' }]);
    }
};

export const markNotificationsAsUnreadApi = async (accessToken, notificationIds) => {
    try {
        const response = await fetch(`${API_URL}/api/notification-bulk-mark-unread/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ notification_ids: notificationIds }),
        });
        return handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to mark notifications as unread' }]);
    }
};

export const deleteNotificationsApi = async (accessToken, notificationIds) => {
    try {
        const response = await fetch(`${API_URL}/api/notification-bulk-delete/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ notification_ids: notificationIds }),
        });
        return handleResponse(response);
    } catch (error) {
        if (error instanceof ApiError) {
            throw error;
        }
        throw new ApiError('API_ERROR', [{ message: 'Failed to delete notifications' }]);
    }
};