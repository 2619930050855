import React, { useEffect, useState, useCallback } from "react";
import AutomationFlowComponent from "./AutomationFlowComponent";
import RunDetailsComponent from "./RunDetailsComponent";
import { fetchProcessFlowDetailApi } from "../../../../api/processFlowApi";
import { useSelector } from "react-redux";
import Loader from "../../../../components/reusableComponents/Loader/Loader";
import DynamicPopup from "../../../../components/reusableComponents/DynamicPopup/DynamicPopup";
import DynamicForm from "../../../../components/reusableComponents/DynamicForm/DynamicForm";
import EmptyState from "../../../../components/reusableComponents/EmptyState/EmptyState";
import { AlertCircle } from 'lucide-react';

const AutomationDetailsPage = ({ selectedLog }) => {
  const [workflowData, setWorkflowData] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [isUploadCompleted, setIsUploadCompleted] = useState(false);
  const [files, setFiles] = useState([]);

  const accessToken = useSelector((state) => state.auth.accessToken);
  const logId = selectedLog?.id;

  useEffect(() => {
    if (!logId) {
      setLoading(false);
      return;
    }

    const fetchWorkflowDetail = async () => {
      setLoading(true);
      try {
        const result = await fetchProcessFlowDetailApi(accessToken, logId);
        if (result) {
          const [workflow] = result.results;
          setWorkflowData(workflow);
          setSelectedNodeId(workflow.graph?.nodes[0]?.id);
        }
      } catch (error) {
        console.error("Error fetching workflow details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkflowDetail();
  }, [logId, accessToken]);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    console.log("Uploaded Files Data:", files);
  }, [files]);

  const handleFileUploadSubmit = useCallback(() => {
    setIsUploadCompleted(true);
    closeModal();
  }, [closeModal]);

  const showPopup = useCallback(() => {
    if (selectedNodeId) setIsModalVisible(true);
  }, [selectedNodeId]);

  if (loading) return <Loader />;

  // Show EmptyState if there's no selectedLog
  if (!selectedLog) {
    return (
      <EmptyState
        icon={AlertCircle}
        title="No Automation Selected"
        message="Please select an automation to view its details"
        iconSize={32}
      />
    );
  }

  // Show EmptyState if there's no workflowData
  if (!workflowData) {
    return (
      <EmptyState
        icon={AlertCircle}
        title="No Workflow Data Available"
        message="Unable to load workflow data. Please try again later."
        iconSize={32}
      />
    );
  }

  const selectedNode = workflowData?.graph?.nodes.find((node) => node.id === selectedNodeId);
  const canPerformAction = selectedNode?.data?.interactive && selectedNode.data.interaction_schema && !isUploadCompleted;

  return (
    <>
      <AutomationFlowComponent
        log={selectedLog}
        workflowData={workflowData}
        onNodeSelected={setSelectedNodeId}
        onPerformActionClick={showPopup}
        canPerformAction={canPerformAction}
      />
      <RunDetailsComponent runDetails={selectedNode?.data?.runDetails} />
      {isModalVisible && canPerformAction && (
        <DynamicPopup
          title="Perform Action"
          onClose={closeModal}
          draggable={false}
          style={{
            minHeight: "25rem",
            minWidth: "65%",
            background: "transparent",
            color: "white",
          }}
          expanded={true}
        >
          <DynamicForm
            config={selectedNode.data.interaction_schema}
            setFiles={setFiles}
            onFormSubmit={handleFileUploadSubmit}
          />
        </DynamicPopup>
      )}
    </>
  );
};

export default AutomationDetailsPage;