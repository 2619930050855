import React, { useState, useCallback } from 'react';
import { HelpCircle, Trash2, Code, Plus } from 'lucide-react';
import { useHistory } from '../sharedHelpers/useHistory';
import EmptyState from '../../../../../../../components/reusableComponents/EmptyState/EmptyState';
import ControlsSection from '../sharedHelpers/ControlsSection';
import styles from './ExpressionContent.module.css';

const ExpressionContent = ({ onClose, onSave, initialConfig }) => {
  const [validationState, setValidationState] = useState({
    validationAttempted: false,
    invalidFields: new Set()
  });

  const {
    current: expressions,
    updateHistory,
    undo,
    redo,
    resetChanges,
    canUndo,
    canRedo,
    hasChanges
  } = useHistory(initialConfig?.expressions || []);

  const handleChange = useCallback((index, value) => {
    const newExpressions = [...expressions];
    newExpressions[index] = value;
    updateHistory(newExpressions);
    
    setValidationState(prev => {
      if (!prev.validationAttempted) return prev;
      
      const newInvalidFields = new Set(prev.invalidFields);
      if (value.trim()) {
        newInvalidFields.delete(index);
      } else {
        newInvalidFields.add(index);
      }
      return {
        ...prev,
        invalidFields: newInvalidFields
      };
    });
  }, [expressions, updateHistory]);

  const handleDelete = useCallback((index) => {
    const newExpressions = expressions.filter((_, i) => i !== index);
    updateHistory(newExpressions);
    setValidationState(prev => {
      const newInvalidFields = new Set(prev.invalidFields);
      newInvalidFields.delete(index);
      return {
        ...prev,
        validationAttempted: false,
        invalidFields: newInvalidFields
      };
    });
  }, [expressions, updateHistory]);

  const handleAdd = useCallback(() => {
    const emptyFieldIndexes = expressions
      .map((expr, index) => (!expr.trim() ? index : -1))
      .filter(index => index !== -1);

    if (emptyFieldIndexes.length > 0) {
      setValidationState(prev => ({
        ...prev,
        validationAttempted: true,
        invalidFields: new Set(emptyFieldIndexes)
      }));
      return;
    }
    
    updateHistory([...expressions, '']);
    setValidationState({
      validationAttempted: false,
      invalidFields: new Set()
    });
  }, [expressions, updateHistory]);

  const handleSave = useCallback(() => {
    const nonEmptyExpressions = expressions.filter(expr => expr.trim());
    if (nonEmptyExpressions.length > 0) {
      onSave?.({
        expressions: nonEmptyExpressions
      });
      resetChanges();
      setValidationState({
        validationAttempted: false,
        invalidFields: new Set()
      });
    }
  }, [expressions, onSave, resetChanges]);

  const hasValidExpressions = expressions.some(expr => expr.trim());
  const showEmptyState = expressions.length === 0;

  return (
    <div className={styles.container}>
      <ControlsSection
        onAdd={handleAdd}
        onUndo={undo}
        onRedo={redo}
        onSave={handleSave}
        onClose={onClose}
        canUndo={canUndo}
        canRedo={canRedo}
        isDisabled={!hasChanges || !hasValidExpressions}
        showAddButton={showEmptyState}
      />
      <div className={styles.contentSection}>
        <div className={styles.contentWrapper}>
          {!showEmptyState && (
            <>
              <div className={styles.header}>
                <div className={styles.headerContent}>
                  <h3 className={styles.headerTitle}>
                    Expression Rules<span className={styles.required}>*</span>
                  </h3>
                  <p className={styles.headerSubtitle}>Configure expressions for this field</p>
                </div>
                <button className={styles.helpButton} title="Help with common examples">
                  <HelpCircle size={24} />
                </button>
              </div>
            </>
          )}
          
          <div className={styles.expressionsContainer}>
            {showEmptyState ? (
              <EmptyState 
                icon={Code}
                title="No expressions configured"
                message="Click the Add button to create an expression"
              />
            ) : (
              <>
                {expressions.map((expression, index) => (
                  <div key={index} className={styles.expressionRow}>
                    <input
                      type="text"
                      value={expression}
                      onChange={(e) => handleChange(index, e.target.value)}
                      className={`${styles.expressionInput} ${
                        (validationState.validationAttempted && !expression.trim()) || 
                        validationState.invalidFields.has(index) ? styles.error : ''
                      }`}
                      placeholder="Enter expression"
                    />
                    <button
                      onClick={() => handleDelete(index)}
                      className={styles.deleteButton}
                      title="Delete expression"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                ))}
                <button
                  onClick={handleAdd}
                  className={styles.addButton}
                  title="Add new expression"
                >
                  <Plus size={16} />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpressionContent;