/**
 * Transforms incomingNodeData JSON structure into the required nodeData format?.
 * @param {Array} incomingData - The incoming node data to be transformed.
 * @returns {Object} Transformed node data.
 */
export const transformNodeData = (incomingData) => {
  const transformedData = {};

  incomingData?.forEach((category) => {
    const groupLabel = category?.category_name;
    const groupIcon = category?.icon;

    const nodes = category?.tasks?.map((task) => {
      return {
        id: task?.name?.toLowerCase()?.replace(/\s+/g, "-"),
        taskId: task.id,
        data: {
          dynamic: {
            configurations: task?.config_schema,
            properties: {
              label: task?.name,
              icon: task?.icon,
              styles: {
                background: task?.icon_background,
                borderRadius: getDynamicBorderRadius(task?.icon_background),
              },
            },
          },
            fieldSchema: task?.field_schema,
            function_identifier: task?.function_identifier,
        },
      };
    });

    transformedData[
      category?.category_name?.toLowerCase()?.replace(/\s+/g, "")
    ] = {
      groupLabel: groupLabel,
      groupIcon: groupIcon,
      nodes: nodes,
    };
  });

  return transformedData;
};


const getDynamicBorderRadius = (backgroundColor) => {
  return backgroundColor ? "15px" : "10px";
};
