import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  ReactFlow,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  Background,
  Controls,
  useReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import GenericNode from "../GenericNode/GenericNode";
import CustomEdge from "../CustomEdge/CustomEdge";
import NodePropertiesModal from "../NodePropertiesModal/NodePropertiesModal";
import DynamicPopup from "../../../components/reusableComponents/DynamicPopup/DynamicPopup";

const nodeTypes = {
  genericNode: GenericNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

const Canvas = ({ nodes, setNodes, edges, setEdges, onInit }) => {
  const reactFlowWrapper = useRef(null);
  const { screenToFlowPosition, setViewport, fitView } = useReactFlow();
  const [selectedNode, setSelectedNode] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Add state to track container dimensions
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Function to update dimensions
  const updateDimensions = useCallback(() => {
    if (reactFlowWrapper.current) {
      const { width, height } = reactFlowWrapper.current.getBoundingClientRect();
      setDimensions({ width, height });
      
      // Fit view whenever the container size changes
      setTimeout(() => {
        fitView({
          padding: 0.2,
          duration: 200,
          maxZoom: 1.5
        });
      }, 50);
    }
  }, [fitView]);

  // Add resize observer
  useEffect(() => {
    const resizeObserver = new ResizeObserver(updateDimensions);
    
    if (reactFlowWrapper.current) {
      resizeObserver.observe(reactFlowWrapper.current);
    }

    return () => resizeObserver.disconnect();
  }, [updateDimensions]);

  // Initial fit view when nodes change
  useEffect(() => {
    if (nodes.length > 0) {
      fitView({
        padding: 0.2,
        duration: 200,
        maxZoom: 1.5
      });
    }
  }, [nodes, fitView]);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const nodeData = JSON.parse(
        event.dataTransfer.getData("application/reactflow"),
      );

      if (!nodeData) return;

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNode = {
        id: `n${Date.now().toString(36)}${Math.random().toString(36).slice(2,5)}`,
        type: "genericNode",
        position,
        data: nodeData.data,
        task_id: nodeData.taskId
      };

      setNodes((nds) => [...nds, newNode]);

      // Fit view after adding new node
      setTimeout(() => {
        fitView({
          padding: 0.2,
          duration: 200,
          maxZoom: 1.5
        });
      }, 50);
    },
    [setNodes, screenToFlowPosition, fitView],
  );

  const onConnect = useCallback((params) => {
    const uniqueId = `e${Math.random().toString(36).slice(2, 8)}`;
    setEdges((eds) => addEdge({ ...params, id: uniqueId, type: "custom", markerEnd: "url(#arrow)", animated: false }, eds));
  }, [setEdges]);

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes],
  );

  const onEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges],
  );

  const onNodeClick = useCallback((event, node) => {
    event.stopPropagation();
    setSelectedNode(node);
    setIsModalVisible(true);
  }, []);

  const handleUpdateNode = useCallback(
    (nodeData) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === nodeData.id
            ? { ...node, data: { ...node.data, ...nodeData.data } }
            : node,
        ),
      );
      setIsModalVisible(false);
    },
    [setNodes],
  );

  return (
    <div
      className="reactflow-wrapper relative w-full h-full"
      ref={reactFlowWrapper}
      style={{ height: "100%" }}
      onDrop={onDrop}
      onDragOver={(event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={(instance) => {
          onInit?.(instance);
          updateDimensions();
        }}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodeClick={onNodeClick}
        fitView
        minZoom={0.1}
        maxZoom={1.5}
        defaultViewport={{ x: 0, y: 0, zoom: 1 }}
      >
        <Background />
        <Controls />
      </ReactFlow>

      {isModalVisible && (
        <DynamicPopup
          title={selectedNode?.data?.dynamic?.properties?.label}
          onClose={() => setIsModalVisible(false)}
          draggable={false}
          style={{minHeight: "30rem"}}
          className="min-h-[25rem] w-full max-w-4xl"
        >
          <NodePropertiesModal
            node={selectedNode}
            onUpdate={handleUpdateNode}
            onClose={() => setIsModalVisible(false)}
            nodes={nodes}
            edges={edges}
          />
        </DynamicPopup>
      )}
    </div>
  );
};

export default Canvas;