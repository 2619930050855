import React, { useEffect, useState } from 'react';
import { Settings } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useConfigurationList } from '../sharedHelpers/useConfigurationList';
import ConfigurationLayout from '../sharedHelpers/ConfigurationLayout';
import TransformationRow from './TransformationRow';
import { fetchTransformationTypes } from '../../../../../../../api/mappingTypeApi';

const TransformationContent = ({ onClose, onSave, initialSteps = [] }) => {
  const [transformationTypes, setTransformationTypes] = useState({});
  const [transformationTypeOptions, setTransformationTypeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const accessToken = useSelector(state => state.auth.accessToken);

  useEffect(() => {
    const loadTransformationTypes = async () => {
      try {
        setIsLoading(true);
        const response = await fetchTransformationTypes(accessToken);
        
        setTransformationTypes(response);
        setTransformationTypeOptions(
          Object.entries(response).map(([value, config]) => ({
            value,
            label: config.label
          }))
        );
      } catch (error) {
        console.error('Failed to load transformation types:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (accessToken) {
      loadTransformationTypes();
    }
  }, [accessToken]);

  const {
    items: steps,
    errors,
    canUndo,
    canRedo,
    handleAdd,
    handleChange,
    handleDelete,
    handleSave,
    undo,
    redo,
    getDisabledItems
  } = useConfigurationList({
    initialItems: initialSteps,
    getTypeParams: (type) => transformationTypes[type]?.params || [],
    requiredField: 'function',
    onSave,
    typeConfigMap: transformationTypes
  });

  const renderTransformationRow = (step, index) => (
    <TransformationRow
      key={step.id}
      step={step}
      functionTypeOptions={transformationTypeOptions}
      transformationTypes={transformationTypes}
      onChange={handleChange}
      onDelete={handleDelete}
      errors={errors[step.id] || {}}
      index={index}
      disabledItems={getDisabledItems(step.id)}
    />
  );

  if (isLoading) {
    return <div>Loading transformation types...</div>;
  }

  return (
    <ConfigurationLayout
      items={steps}
      onAdd={handleAdd}
      onSave={handleSave}
      onClose={onClose}
      onUndo={undo}
      onRedo={redo}
      canUndo={canUndo}
      canRedo={canRedo}
      emptyStateIcon={Settings}
      emptyStateTitle="No transformation steps configured"
      emptyStateMessage="Click the Add button to create a transformation step"
      headerTitle="Transformation Rules"
      headerSubtitle="Configure transformation rules for this field"
      renderItem={renderTransformationRow}
    />
  );
};

export default TransformationContent;