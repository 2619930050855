import React, { memo, useCallback } from "react";
import { Reorder } from "framer-motion";
import { Plus } from "lucide-react"; // Add this import
import MappingFieldRow from "./MappingFieldRow/MappingFieldRow";
import TableHeader from "./MappingFieldRow/TableHeader/TableHeader";
import styles from "./MappingFieldsList.module.css";

const MappingFieldsList = memo(({
  mappingFields,
  sourceNode,
  currentNode,
  onFieldMappingChange,
  onDelete,
  onReorder,
  onAdd
}) => {
  const sortedMappingFields = [...mappingFields].sort((a, b) =>
    (a.position ?? Infinity) - (b.position ?? Infinity)
  );

  const handleReorder = useCallback((reorderedFields) => {
    const fieldsWithNewPositions = reorderedFields.map((field, index) => ({
      ...field,
      position: index
    }));
    
    onReorder(fieldsWithNewPositions);
  }, [onReorder]);

  const handleAddField = useCallback(() => {
    const newPosition = sortedMappingFields.length;
    onAdd(newPosition);
  }, [sortedMappingFields.length, onAdd]);

  return (
    <div className={styles.container}>
      <TableHeader />
      <Reorder.Group
        axis="y"
        values={sortedMappingFields}
        onReorder={handleReorder}
        className={styles.fieldsList}
      >
        {sortedMappingFields.map((mappingField) => (
          <Reorder.Item
            key={mappingField.id}
            value={mappingField}
            dragListener={sortedMappingFields.length > 1}
          >
            <MappingFieldRow
              mappingField={mappingField}
              onFieldMappingChange={onFieldMappingChange}
              onDelete={() => onDelete(mappingField.id)}
              sourceNode={sourceNode}
              currentNode={currentNode}
              position={mappingField.position}
            />
          </Reorder.Item>
        ))}
      </Reorder.Group>
      
      <button
        onClick={handleAddField}
        className={`${styles.addButton} ${sortedMappingFields.some(field => !field.targetField || (field.mappingType !== 'static' && !field.sourceField)) ? styles.disabled : ''}`}
        disabled={sortedMappingFields.some(field => !field.targetField || (field.mappingType !== 'static' && !field.sourceField))}
        title="Add field mapping"
      >
        <Plus size={16} />
      </button>


    </div>
  );
});

MappingFieldsList.displayName = 'MappingFieldsList';

export default MappingFieldsList;