import React, { useEffect, useState } from 'react';
import { Info } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useConfigurationList } from '../sharedHelpers/useConfigurationList';
import ConfigurationLayout from '../sharedHelpers/ConfigurationLayout';
import ValidationRow from './ValidationRow';
import { fetchValidationTypes } from '../../../../../../../api/mappingTypeApi';

// Function to remove 'c_' prefix from object keys
function removePrefix(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newKey = key.startsWith('c_') ? key.slice(2) : key;
    acc[newKey] = value;
    return acc;
  }, {});
}

const ValidationConfig = ({ onClose, onSave, initialConfig = [] }) => {
  const [validationTypes, setValidationTypes] = useState({});
  const [validationTypeOptions, setValidationTypeOptions] = useState([]);
  const accessToken = useSelector(state => state.auth.accessToken);

  useEffect(() => {
    const loadValidationTypes = async () => {
      try {
        const response = await fetchValidationTypes(accessToken);
        const cleanedTypes = removePrefix(response);
        
        setValidationTypes(cleanedTypes);
        setValidationTypeOptions(
          Object.entries(cleanedTypes).map(([value, config]) => ({
            value,
            label: config.label
          }))
        );
      } catch (error) {
        console.error('Failed to load validation types:', error);
      }
    };

    if (accessToken) {
      loadValidationTypes();
    }
  }, [accessToken]);

  const {
    items: validations,
    errors,
    canUndo,
    canRedo,
    handleAdd,
    handleChange,
    handleDelete,
    handleSave,
    undo,
    redo,
    getDisabledItems
  } = useConfigurationList({
    initialItems: initialConfig,
    getTypeParams: (type) => validationTypes[type]?.params || [],
    requiredField: 'type',
    onSave,
    typeConfigMap: validationTypes
  });

  const renderValidationRow = (validation, index) => (
    <ValidationRow
      key={validation.id}
      validation={validation}
      validationTypeOptions={validationTypeOptions}
      onChange={handleChange}
      onDelete={handleDelete}
      errors={errors[validation.id] || {}}
      index={index}
      disabledItems={getDisabledItems(validation.id)}
    />
  );

  return (
    <ConfigurationLayout
      items={validations}
      onAdd={handleAdd}
      onSave={handleSave}
      onClose={onClose}
      onUndo={undo}
      onRedo={redo}
      canUndo={canUndo}
      canRedo={canRedo}
      emptyStateIcon={Info}
      emptyStateTitle="No validations configured"
      emptyStateMessage="Click the Add button to create a validation rule"
      headerTitle="Validation Rules"
      headerSubtitle="Configure validation rules for this field"
      renderItem={renderValidationRow}
    />
  );
};

export default ValidationConfig;