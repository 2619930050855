import React from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
} from "@xyflow/react";
import { useSelector } from "react-redux";
import { X } from "lucide-react";
import { motion } from "framer-motion";
import styles from "./buttonedge.module.css";

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
}) => {
  const { setEdges } = useReactFlow();
  const mode = useSelector((state) => state.workflow.mode);

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onRemoveEdgeClick = (event) => {
    event.stopPropagation();
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  };

  return (
    <>
      <svg style={{ position: "absolute", pointerEvents: "none" }}>
        <defs>
          <marker
            id={`arrow-${id}`}
            markerWidth="6"
            markerHeight="6"
            refX="5"
            refY="3"
            orient="auto"
            markerUnits="strokeWidth"
          >
            <path d="M0,0 L0,6 L6,3 z" fill="#000" />
          </marker>
        </defs>
      </svg>

      <BaseEdge
        path={edgePath}
        markerEnd={`url(#arrow-${id})`}
        style={{ ...style, strokeWidth: 1.7, stroke: "#000" }}
      />

      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(${labelX}px, ${labelY}px) translate(-50%, -50%)`,
            pointerEvents: "auto",
          }}
          className={styles["edge-container"]}
        >
          {mode === "edit" && (
            <motion.button
              className={styles["edge-delete-button"]}
              onClick={onRemoveEdgeClick}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
              <X size={12} strokeWidth={2.5} />
            </motion.button>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;