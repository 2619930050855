// utils/validationUtils.js

const VALIDATION_RULES = {
    required: (value, label) => (!value || (Array.isArray(value) && value.length === 0)) 
        ? `${label} is required` 
        : '',
        
    email: (value, label) => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
        ? `${label} must be a valid email address`
        : '',
        
    url: (value, label) => !/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(value)
        ? `${label} must be a valid URL`
        : '',
        
    tel: (value, label) => !/^[+]?[\d\s-()]*$/.test(value)
        ? `${label} must be a valid phone number`
        : '',
        
    minLength: (value, label, min) => String(value).length < min
        ? `${label} should be at least ${min} characters`
        : '',
        
    maxLength: (value, label, max) => String(value).length > max
        ? `${label} should be less than ${max} characters`
        : '',
        
    pattern: (value, label, pattern) => !new RegExp(pattern).test(value)
        ? `${label} is not in the correct format`
        : '',
        
    min: (value, label, min) => Number(value) < min
        ? `${label} should be at least ${min}`
        : '',
        
    max: (value, label, max) => Number(value) > max
        ? `${label} should be at most ${max}`
        : '',
};

export const getValidationAttributes = (field) => {
    if (!field.validation) return {};
    return Object.entries(field.validation).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
    }, {});
};

export const validateField = (field, value) => {
    const { validation = {}, label } = field;
    
    // Skip validation if value exists and we're not checking required
    if (value && !validation.required) return '';

    // Required validation takes precedence
    if (validation.required) {
        const requiredError = VALIDATION_RULES.required(value, label);
        if (requiredError) return requiredError;
    }

    // Skip other validations if value is empty and not required
    if (!value && !validation.required) return '';

    // Check each validation rule
    for (const [rule, ruleValue] of Object.entries(validation)) {
        if (rule === 'required') continue;
        
        if (rule === 'type' && VALIDATION_RULES[ruleValue]) {
            const error = VALIDATION_RULES[ruleValue](value, label);
            if (error) return error;
        } else if (VALIDATION_RULES[rule]) {
            const error = VALIDATION_RULES[rule](value, label, ruleValue);
            if (error) return error;
        }
    }

    return '';
};

export const isFieldValid = (field, value) => validateField(field, value) === '';

export const validateSingleItem = (item, getFieldParams, requiredField = null) => {
    if (!item) return false;
    
    // Check required field if specified
    if (requiredField && !item[requiredField]) return false;
    
    // Get field parameters if available
    const params = getFieldParams ? getFieldParams(item[requiredField]) : [];
    
    // If no parameters to validate, just check the required field
    if (!params || params.length === 0) return Boolean(item[requiredField]);
    
    // Validate each parameter that has validation rules
    return params.every(param => {
        if (!param.validation?.required) return true;
        const value = item.params?.[param.name];
        return value !== undefined && value !== '' && value !== null;
    });
};

const isItemValid = (item, params) => {
    if (!item || !params) return false;
    
    // Check if the item has all required parameters with valid values
    return params.every(param => {
        if (!param.validation?.required) return true;
        const value = item.params?.[param.name];
        return value !== undefined && value !== '' && 
               !(Array.isArray(value) && value.length === 0);
    });
};

export const validateItems = (items, getFieldParams, options = {}) => {
    const {
        showValidation = false,
        requiredField = null,
        itemLabel = 'Item',
        skipValidItems = false
    } = options;

    if (!showValidation || !Array.isArray(items)) {
        return { errors: {}, isValid: true };
    }

    const errors = {};
    let isValid = true;

    items.forEach(item => {
        if (!item?.id) return;
        const itemErrors = {};
        // Get params for the current type
        const params = getFieldParams ? getFieldParams(item[requiredField]) : [];

        // Skip validation for items that are already valid
        if (skipValidItems && item[requiredField] && isItemValid(item, params)) {
            return;
        }

        // Validate required field if specified
        if (requiredField && !item[requiredField]) {
            itemErrors[requiredField] = `${itemLabel} is required`;
            isValid = false;
        }

        // Validate parameters if the required field exists
        if (requiredField && item[requiredField]) {
            params.forEach(param => {
                if (param.validation) {
                    const value = item.params?.[param.name] || item.params?.value;
                    
                    // Skip validation if value exists and we're skipping valid items
                    if (skipValidItems && value !== undefined && value !== '' && 
                        !(Array.isArray(value) && value.length === 0)) {
                        return;
                    }

                    const error = validateField(param, value);
                    if (error) {
                        itemErrors[param.name] = error;
                        isValid = false;
                    }
                }
            });
        }

        if (Object.keys(itemErrors).length > 0) {
            errors[item.id] = itemErrors;
        }
    });

    return { errors, isValid };
};