import { SET_MODE, SET_SELECTED_NODE, SET_NODES } from '../actions/actions';

// Initial State
const initialState = {
  mode: 'view',
  selectedNodeId: null,
  nodes: []
};

// Reducer
export default function workflowReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case SET_SELECTED_NODE:
      return {
        ...state,
        selectedNodeId: action.payload,
      };
    case SET_NODES:
      return {
        ...state,
        nodes: action.payload,
      };
    default:
      return state;
  }
}
