// MappingFieldRow.js
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { GripVertical, MoveRight, Pencil } from "lucide-react";
import CustomDropdown from "../../../../../../components/reusableComponents/CustomDropdown/CustomDropdown";
import DynamicPopup from "../../../../../../components/reusableComponents/DynamicPopup/DynamicPopup";
import { dataTypeOptions, mappingTypeOptions } from "./MappingFieldConstants";
import updateMappingFieldValidations from './fieldValidationUtils';
import ActionButtons from "./ActionButtons/ActionButtons";
import {
  MAPPING_TYPES,
  transformSchemaToDropdownFormat,
  findDataTypeInSchema,
  createPopupContent,
  getActionButtonStates,
} from "./MappingFieldRowHelpers";
import styles from "./MappingFieldRow.module.css";

const dropdownStyle = {
  boxSizing: 'border-box',
  borderRadius: '8px',
  height: '2.25rem'
};

const MappingFieldRow = memo(({ mappingField, onFieldMappingChange, onDelete, sourceNode, currentNode }) => {
  // State management
  const [activePopup, setActivePopup] = useState(null);
  const [customFields, setCustomFields] = useState({
    source: { isCustom: false, value: '' },
    target: { isCustom: false, value: '' }
  });

  // Get nodes from Redux store
  const nodes = useSelector(state => state.workflow.nodes);
  const [sourceSchema, targetSchema] = [sourceNode, currentNode].map(node => 
    nodes.find(n => n.data?.function_identifier === node.data?.function_identifier)
    ?.data?.fieldSchema
  );

  // Transform schemas to dropdown format
  const sourceFields = transformSchemaToDropdownFormat(sourceSchema?.output);
  const targetFields = transformSchemaToDropdownFormat(targetSchema?.input);

  const isStaticMapping = mappingField.mappingType === MAPPING_TYPES.STATIC;

  // Field update handlers
  const updateField = (field, value) => {
    onFieldMappingChange(mappingField.id, field, value);
  };

  const handleFieldChange = (field) => (value) => {
    if (field === 'mappingType') {
      handleMappingTypeChange(value);
      return;
    }

    updateField(field, value);

    // Handle data type updates
    if (field === 'sourceField' && !customFields.source.isCustom) {
      const dataType = findDataTypeInSchema(sourceSchema?.output, value);
      if (dataType) updateField('sourceDataType', dataType);
    } else if (field === 'targetField' && !customFields.target.isCustom) {
      handleTargetFieldUpdate(value);
    }
  };

  const handleMappingTypeChange = (value) => {
    const isSpecialType = ['transform', 'lookup', 'expression', 'static'].includes(value);
    
    if (isSpecialType) {
      setActivePopup(value);
      if (value === MAPPING_TYPES.STATIC) {
        updateField('sourceField', null);
        updateField('sourceDataType', null);
      }
      return;
    }

    updateField('mappingType', value);
  };

  const handleTargetFieldUpdate = (value) => {
    // Update validations
    const updatedField = updateMappingFieldValidations(
      mappingField,
      targetSchema?.input,
      value
    );

    if (updatedField.validations) {
      updateField('validations', updatedField.validations);
    }

    // Update data type
    const dataType = findDataTypeInSchema(targetSchema?.input, value);
    if (dataType) updateField('targetDataType', dataType);
  };

  // Custom input handlers
  const toggleCustomInput = (fieldType) => () => {
    if (fieldType === 'source' && isStaticMapping) return;

    setCustomFields(prev => {
      const field = prev[fieldType];
      const currentValue = fieldType === 'source' ? mappingField.sourceField : mappingField.targetField;
      
      return {
        ...prev,
        [fieldType]: {
          isCustom: !field.isCustom,
          value: !field.isCustom ? currentValue || '' : field.value
        }
      };
    });
  };

  const handleCustomInputChange = (fieldType) => (e) => {
    const value = e.target.value;
    setCustomFields(prev => ({
      ...prev,
      [fieldType]: { ...prev[fieldType], value }
    }));
    updateField(fieldType === 'source' ? 'sourceField' : 'targetField', value);
  };

  // Popup handlers
  const handlePopupSave = (type) => (data) => {
    console.log('Popup save data:', { type, data });
    let field, value;

    switch(type) {
      case MAPPING_TYPES.TRANSFORM:
        field = 'transformation';
        value = {
          initialValue: mappingField.sourceField,
          steps: data
        };
        break;
      case MAPPING_TYPES.LOOKUP:
        field = 'lookup';
        value = data;
        break;
      case MAPPING_TYPES.EXPRESSION:
        field = 'expression';
        value = data;
        break;
      case MAPPING_TYPES.STATIC:
        field = 'staticValue';
        value = data.staticValue;
        break;
      case 'validation':
        field = 'validations';
        value = data;
        break;
      default:
        return;
    }

    if (field) {
      console.log('Updating field:', field, 'with value:', value);
      updateField(field, value);
      
      if (type !== 'validation') {
        updateField('mappingType', type);
        clearOtherMappingFields(type);
      }
    }

    setActivePopup(null);
  };

  const clearOtherMappingFields = (currentType) => {
    const fields = {
      [MAPPING_TYPES.TRANSFORM]: 'transformation',
      [MAPPING_TYPES.LOOKUP]: 'lookup',
      [MAPPING_TYPES.EXPRESSION]: 'expression',
      [MAPPING_TYPES.STATIC]: 'staticValue'
    };

    Object.entries(fields).forEach(([type, field]) => {
      if (type !== currentType) updateField(field, null);
    });
  };

  // Render field input (either dropdown or custom input)
  const renderFieldInput = (fieldType, options, value, disabled = false) => {
    const isCustom = customFields[fieldType].isCustom;
    
    return (
      <div className={styles.fieldInputContainer}>
        {isCustom ? (
          <input
            type="text"
            value={customFields[fieldType].value}
            onChange={handleCustomInputChange(fieldType)}
            className={styles.customInput}
            placeholder="Enter field name"
            disabled={disabled}
          />
        ) : (
          <CustomDropdown
            items={options}
            value={value}
            onChange={handleFieldChange(fieldType === 'source' ? 'sourceField' : 'targetField')}
            style={dropdownStyle}
            placeholder="Select Field"
            choosenShouldShow="value"
            disabled={disabled}
          />
        )}
        <button
          onClick={toggleCustomInput(fieldType)}
          className={`${styles.typeToggle} ${
            isCustom ? styles.typeToggleActive : ''
          } ${disabled ? styles.disabled : ''}`}
          title={disabled ? "Disabled in static mode" : (isCustom ? "Switch to dropdown" : "Switch to custom input")}
          disabled={disabled}
        >
          <Pencil size={20} />
        </button>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.fieldRow}>
        <div className={styles.dragHandle}>
          <GripVertical className={styles.dragIcon} />
        </div>

        <div className={styles.fieldSelectors}>
          {/* Source Fields */}
          <div className={styles.fieldPair}>
            <div className={`${styles.fieldContainer} ${styles.field}`}>
              <label className={styles.fieldLabel}>Source Field</label>
              {renderFieldInput('source', sourceFields, mappingField.sourceField, isStaticMapping)}
            </div>
            <div className={`${styles.fieldContainer} ${styles.type}`}>
              <label className={styles.fieldLabel}>Source Type</label>
              <CustomDropdown
                items={dataTypeOptions}
                value={isStaticMapping ? null : mappingField.sourceDataType}
                onChange={handleFieldChange('sourceDataType')}
                style={dropdownStyle}
                placeholder="Data Type"
                choosenShouldShow="label"
                disabled={isStaticMapping}
              />
            </div>
          </div>

          <span className={styles.mappingArrow}>
            <MoveRight />
          </span>

          {/* Target Fields */}
          <div className={styles.fieldPair}>
            <div className={`${styles.fieldContainer} ${styles.field}`}>
              <label className={styles.fieldLabel}>Target Field</label>
              {renderFieldInput('target', targetFields, mappingField.targetField)}
            </div>
            <div className={`${styles.fieldContainer} ${styles.type}`}>
              <label className={styles.fieldLabel}>Target Type</label>
              <CustomDropdown
                items={dataTypeOptions}
                value={mappingField.targetDataType}
                onChange={handleFieldChange('targetDataType')}
                style={dropdownStyle}
                placeholder="Data Type"
                choosenShouldShow="label"
              />
            </div>
          </div>

          {/* Mapping Type and Actions */}
          <div className={styles.actions}>
            <div className={styles.fieldContainer}>
              <label className={styles.fieldLabel}>Mapping Type</label>
              <CustomDropdown
                items={mappingTypeOptions}
                value={mappingField.mappingType}
                onChange={handleFieldChange('mappingType')}
                style={dropdownStyle}
                placeholder="Select Type"
              />
            </div>
            <ActionButtons 
              onDelete={onDelete}
              onTransform={() => setActivePopup(MAPPING_TYPES.TRANSFORM)}
              onLookup={() => setActivePopup(MAPPING_TYPES.LOOKUP)}
              onExpression={() => setActivePopup(MAPPING_TYPES.EXPRESSION)}
              onStatic={() => setActivePopup(MAPPING_TYPES.STATIC)}
              onValidate={() => setActivePopup('validation')}
              field={mappingField}
              {...getActionButtonStates(mappingField)}
            />
          </div>
        </div>
      </div>

      {/* Popup */}
      {activePopup && (() => {
        const { title, Component, props } = createPopupContent(mappingField)[activePopup];
        return (
          <DynamicPopup
            title={title}
            onClose={() => setActivePopup(null)}
            style={{ minHeight: "25rem", minWidth: "65%", background: "#fff" }}
            expanded={true}
          >
            <Component
              {...props}
              onSave={handlePopupSave(activePopup)}
              onClose={() => setActivePopup(null)}
            />
          </DynamicPopup>
        );
      })()}
    </div>
  );
});

MappingFieldRow.displayName = 'MappingFieldRow';
export default MappingFieldRow;