// DropdownItem.jsx
import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as DropdownIconClosed } from '../../../assetts/images/dropdownIconClosed.svg';
import TruncatedText from './TruncatedText';
import { normalizePath } from './utils';
import styles from './DropdownItem.module.css';

const DropdownItem = ({ 
  item, 
  level = 0, 
  onSelect, 
  isSelected, 
  multiSelect, 
  value,
  choosenShouldShow = 'label',
  disabled = false,
  disabledItems = []
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const itemRef = useRef(null);
  const hasChildren = item.children?.length > 0;
  
  const normalizedItemValue = normalizePath(String(item.value));
  const normalizedValue = value !== undefined ? normalizePath(String(value)) : '';
  const isItemDisabled = disabled || disabledItems.includes(String(item.value));

  const checkSelectedState = (currentItem, currentValue) => {
    const currentNormalizedValue = normalizePath(String(currentItem.value));
    
    if (currentNormalizedValue === normalizedValue) return true;
    return currentItem.children?.some(child => checkSelectedState(child, currentValue)) || false;
  };

  useEffect(() => {
    if ((value !== undefined && checkSelectedState(item, value)) || item.expanded) {
      setIsExpanded(true);
    }
  }, [item, value]);

  useEffect(() => {
    if (!isExpanded || !itemRef.current) return;

    const dropdownList = itemRef.current.closest('[class*="list"]');
    if (!dropdownList) return;

    const itemHeight = itemRef.current.scrollHeight;
    const listHeight = dropdownList.clientHeight;
    const itemTop = itemRef.current.offsetTop;
    
    const targetScroll = itemTop - (listHeight - itemHeight) / 2;
    
    dropdownList.scrollTo({
      top: targetScroll,
      behavior: 'smooth'
    });
  }, [isExpanded]);

  const handleClick = (e) => {
    e.stopPropagation();
    if (hasChildren) {
      setIsExpanded(!isExpanded);
    } else if (!isItemDisabled) {
      onSelect(item.value);
    }
  };

  const isItemSelected = multiSelect
    ? isSelected?.includes(normalizedItemValue)
    : normalizedValue === normalizedItemValue;

  const displayText = choosenShouldShow === 'value' 
    ? String(item.value) 
    : String(item.label || '');

  return (
    <>
      <div
        ref={itemRef}
        className={`${styles.item} 
          ${isItemSelected ? styles.selected : ''} 
          ${hasChildren ? styles.parent : ''} 
          ${isItemDisabled ? styles.disabled : ''}`}
        style={{ paddingLeft: `${level + 1}rem` }}
        onClick={handleClick}
      >
        <div className={styles.content}>
          <TruncatedText 
            text={displayText} 
            className={`${styles.label} ${isItemDisabled ? styles.disabledText : ''}`} 
          />
          {hasChildren && (
            <DropdownIconClosed 
              className={`${styles.icon} ${isExpanded ? styles.expanded : ''} 
                ${isItemDisabled ? styles.disabledIcon : ''}`} 
            />
          )}
        </div>
      </div>

      {hasChildren && isExpanded && (
        <div className={styles.nested}>
          {item.children.map(child => (
            <DropdownItem
              key={String(child.value)}
              item={child}
              level={level + 1}
              onSelect={onSelect}
              isSelected={isSelected}
              multiSelect={multiSelect}
              value={value}
              choosenShouldShow={choosenShouldShow}
              disabled={disabled}
              disabledItems={disabledItems}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default DropdownItem;